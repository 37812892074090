import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  localStorage: Storage;

  changes$ = new Subject();

  constructor() {
    this.localStorage   = window.localStorage;
  }

  get(key: string): any {
    if (this.isLocalStorageSupported) {
      let value = this.localStorage.getItem(key);
        if(value == 'undefined'){
          return '';
        }else{
          return JSON.parse(this.localStorage.getItem(key));
        }
      
    }

    return null;
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.setItem(key, JSON.stringify(value));
      this.changes$.next({
        type: 'set',
        key,
        value
      });
      return true;
    }

    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      this.changes$.next({
        type: 'remove',
        key
      });
      return true;
    }

    return false;
  }

  removeAll(): void {
    if (this.isLocalStorageSupported) {
      this.localStorage.clear();
    }
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage
  }
}
