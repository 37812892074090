<!-- -->
<div class="container-fluid h-100 background-dis">
    <div class="row">
        <div class="col-12 h-100">
            <div class="row">
                <div class="col-sm-12">
                </div>
            </div>
            <div class="container">
                <!-- Row -->
                <div class="table-struct w-100">
                    <div class="table-cell auth-form-wrap">
                        <div class="auth-form mx-auto form-content">
                            <div class="col-12">
                                <img src="/../assets/img/logo-cane.svg" class="img-fluid logo-login d-block mx-auto">
                            </div>
                            <div class="mb-30">
                                <h5 class="text-center mb-30">Recupera tu contraseña</h5>
                            </div>
                            <div class="form-wrap">
                                <ngx-spinner bdColor="rgba(5,0,248,0.8)" size="default" color="#fff" type="ball-spin"
                                    [fullScreen]="false">
                                    <p style="color: white"> Cargando... </p>
                                </ngx-spinner>
                                <p-confirmPopup autoZIndex="false" baseZIndex="1"></p-confirmPopup>
                                <p-toast position="bottom-right"></p-toast>
                                <form name="form" id="formPassword" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm"
                                    class="needs-validation" novalidate>
                                    <div class="form-group">
                                        <label for="user">Usuario</label>
                                        <input type="text" class="form-control" name="usuario" id="usuario"
                                            [(ngModel)]="passwordValidar.usuario" required #usuario="ngModel"
                                            placeholder="Ingresa tu usuario">
                                        <div class="invalid-feedback">

                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="password">Correo</label>
                                        <input type="email" class="form-control" name="email" id="email"
                                            [(ngModel)]="passwordValidar.email" required minlength="6" #email="ngModel"
                                            placeholder="Ingresa tu correo">
                                        <div class="invalid-feedback">

                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-nom-content btn-rounded" type="submit">Ingresar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="text-white">Versión 1.0</p>
                <!--/Row-->
            </div>
        </div>
    </div>
</div>