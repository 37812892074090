import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConstantsService } from "../util/constants.services";

@Injectable({
    providedIn: 'root'
})
export class BeneficiariosService {
    private constantsServices: ConstantsService = new ConstantsService();

    constructor(private http: HttpClient) { }

    public actualizarDatosBeneficiarios(): Observable<any> {
        return this.http.get(this.constantsServices.BENEFICIARIOS_API, { observe: 'response' });
    }

    public subirArchivoDatosBeneficiarios(formArchivoDatosBeneficiarios: FormData): Observable<any> {
        return this.http.post(this.constantsServices.BENEFICIARIOS_API, formArchivoDatosBeneficiarios, { observe: 'response' });
    }

    public getTenLastLogCargaEmpleados(): Observable<any> {
        return this.http.get(this.constantsServices.LOG_CARGA_EMPLEADOS_API, {observe: 'response'});
    }

    public getArchivoEmpleados(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.constantsServices.ARCHIVO_BENEFICIARIOS, {headers, responseType: 'blob' as 'json'});
    }

    public getListaArchivos(): Observable<any> {
        return this.http.get(this.constantsServices.LISTAR_ARCHIVOS_BENEFICIARIOS, {observe: 'response'});
    }

    public getInformacionArchivoSeleccionado(archivo:string): Observable<any> {
        return this.http.get(this.constantsServices.INFORMACION_ARCHIVOS + '/' + archivo, {observe: 'response'});
    }
}