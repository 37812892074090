import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { ConstantsService } from '../util/constants.services';
import { PasswordValidar } from '../models/password-validar';
import { Correo } from '../models/correo';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private constantsServices: ConstantsService = new ConstantsService();

  constructor(private http: HttpClient) { }

  login(usuarioLogin: Usuario): Observable<any> {
    return this.http.post(this.constantsServices.LOGIN_API, usuarioLogin);
  }

  validarUsuario(passwordValidar: PasswordValidar): Observable<any> {
    return this.http.post(this.constantsServices.LOGIN_API + '/password', passwordValidar, {observe: 'response'});
  }

  enviarCorreo(correo: Correo){
    return this.http.post(this.constantsServices.EMAIL_API, correo, {observe: 'response'});
  }

  actualizarContrasenia (passwordActualizar: Usuario){
    return this.http.put(this.constantsServices.LOGIN_API + '/password', passwordActualizar, {observe: 'response'});
  }

}