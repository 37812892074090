import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class ConstantsService {

    LOGIN_API: string;
    EMAIL_API: string;
    DISPERSIONES_API: string;
    DISPERSIONES_DESCARGAR_CEP_API: string;
    DISPERSIONES_TNN_CAMBIAR_ESTATUS_LIQUIDADAS: string;
    CATALOGO_BENEFICIARIOS_TIPO_API: string;
    CATALOGO_DEPARTAMENTOS_API: string;
    CATALOGO_CENTROS_COSTOS_API: string;
    CATALOGO_CENTROS_TRABAJO_API: string;
    DISPERSIONES_PANTALLA_PRINCIPAL_API: string;
    DISPERSIONES_LAYOUTS_OPERACIONES_API: string;
    CATALOGO_ESTADO_DISPERSION_API: string;
    DESCARGA_RECIBOS_ZIP: string;
    DESCARGA_RECIBO_DISPERSION: string;
    DESCARGA_RECIBOS_DISPERSION_ZIP: string;
    BENEFICIARIOS_API: string;
    REPROCESO_DISPERSION: string;
    LOG_CARGA_EMPLEADOS_API: string;
    ENVIO_COMISION: string;
    SALDO_CUENTAS: string;
    ARCHIVO_BENEFICIARIOS: string;
    ARCHIVO_DISPERSIONES: string;
    CARGA_MULTIPLE: string;

    //Ids Perfiles de usuario

    ID_PERFIL_OPERADOR: number;
    ID_PERFIL_GERENTE: number;
    ID_PERFIL_GERENTE_REGIONAL: number;

    constructor() {
        this.LOGIN_API = environment.URL_SERVICES_1 + '/usuario';
        this.EMAIL_API = environment.URL_SERVICES_2 + '/email/send';
        this.DISPERSIONES_API = environment.URL_SERVICES_2 + '/dispersiones';
        this.DISPERSIONES_DESCARGAR_CEP_API = environment.URL_SERVICES_2 + '/dispersiones/cep';
        this.DISPERSIONES_TNN_CAMBIAR_ESTATUS_LIQUIDADAS = environment.URL_SERVICES_2 + '/dispersiones/tnn';
        this.CATALOGO_BENEFICIARIOS_TIPO_API = environment.URL_SERVICES_2 + '/dispersiones/beneficiario-tipos';
        this.CATALOGO_DEPARTAMENTOS_API = environment.URL_SERVICES_2 + '/dispersiones/catalogo-departamentos';
        this.CATALOGO_CENTROS_COSTOS_API = environment.URL_SERVICES_2 + '/dispersiones/catalogo-centros-costos';
        this.CATALOGO_CENTROS_TRABAJO_API = environment.URL_SERVICES_2 + '/dispersiones/catalogo-centros-trabajo';
        this.DISPERSIONES_PANTALLA_PRINCIPAL_API = environment.URL_SERVICES_2 + '/dispersiones/pantalla-principal';
        this.DISPERSIONES_LAYOUTS_OPERACIONES_API = environment.URL_SERVICES_2 + '/dispersiones/layouts';
        this.CATALOGO_ESTADO_DISPERSION_API = environment.URL_SERVICES_1 + '/dispersiones/cat-estados';
        this.BENEFICIARIOS_API = environment.URL_SERVICES_1 + '/beneficiarios';
        this.LOG_CARGA_EMPLEADOS_API = environment.URL_SERVICES_1 + '/beneficiarios/log-carga-empleados';

        this.DESCARGA_RECIBOS_ZIP = environment.URL_SERVICES_1 + '/recibosDispersion/descargaZip';
        //this.DESCARGA_RECIBO_DISPERSION = environment.URL_SERVICES_1 + '/recibosDispersion/descargaReciboPdf';
        this.DESCARGA_RECIBO_DISPERSION = environment.URL_SERVICES_1 + '/recibosDispersion/descargaReciboPdf';
        this.DESCARGA_RECIBOS_DISPERSION_ZIP = environment.URL_SERVICES_1 + '/recibosDispersion/descargaRecibosZip';

        this.REPROCESO_DISPERSION = environment.URL_SERVICES_1 + '/envioscane/reprocesar';

        this.ENVIO_COMISION = environment.URL_SERVICES_1 + '/dispersiones/envioComision';
        this.SALDO_CUENTAS = environment.URL_SERVICES_1 + '/dispersiones/saldoCuentas';
        this.ARCHIVO_BENEFICIARIOS = environment.URL_SERVICES_1 + '/beneficiarios/concentrado/empleados';
        this.ARCHIVO_DISPERSIONES = environment.URL_SERVICES_1 + '/dispersiones/layout/cargaMultiple';
        this.CARGA_MULTIPLE = environment.URL_SERVICES_1 + '/dispersiones/comisiones/carga-multiple';
        //Inicialización de ids Perfiles de usuario

        this.ID_PERFIL_OPERADOR = 1;
        this.ID_PERFIL_GERENTE = 2;
        this.ID_PERFIL_GERENTE_REGIONAL = 3;
    }
}