<div class="wrapper d-flex">
    <div class="page-wrapper">
        <main>
            <div class="row title-wraper">
                <div class="col-12 col-sm-6 col-md-6 title-page-fd">
                    <h2>Saldo de cuentas</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-12 tabla-operaciones">
                    <div class="row content-wrapper-inner mt2">
                        <!--<div class="col-1"></div>-->
                        <div class="table-striped table-bordered">
                            <table>
                                <tr class="head-top">
                                    <th>Empresa</th>
                                    <th>Saldo</th>
                                </tr>
                                <tr *ngFor="let dato of cuentas">
                                    <td>{{dato.empresa}}</td>
                                    <td>{{dato.saldo}}</td>
                                </tr>
                            </table>
                        </div>
                        <!--<div class="col-1"></div>-->
                    </div>
                </div>
                
            </div>
            <br><br><br>
        </main>
    </div>
</div>
