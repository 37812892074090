<nav class="navbar navbar-expand-lg navbar-light bg-light ">
    <!-- <button class="navbar-toggler sideMenuToggler" type="button">
        <span class="navbar-toggler-icon"></span>
    </button> -->
    <div class="col-10 p-0">
        <a class="navbar-brand" href="login">Seguimiento de Dispersión - {{nombrePerfil}}</a>
    </div>
    <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button> -->

    <p-toast position="bottom-right"></p-toast>

    <div class="col-2" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
                <button onclick="myFunction()" class="dropbtn">{{nombreUsuario}}</button>
                <div id="myDropdown" class="dropdown-content">
                  <a href="">Cerrar Sesión</a>
                </div>
            </li>
            <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="login" id="navbarDropdown" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">{{nombreUsuario}}</a>
            </li> -->
        </ul>
    </div>
    
</nav>
