import { Component, OnInit } from '@angular/core';
import { SaldoCuentas } from 'src/app/models/saldo-cuenta';
import { DispersionesService } from 'src/app/services/dispersiones.service';

@Component({
  selector: 'app-consulta-saldo',
  templateUrl: './consulta-saldo.component.html'
})
export class ConsultaSaldoComponent implements OnInit {

  cuentas: SaldoCuentas[] = new Array();

  formatter1 = new Intl.NumberFormat('es-MX',{
    style: 'currency',
    currency: 'MXN'
  });

  constructor(private dispersionesService: DispersionesService) { }

  ngOnInit(): void {
    this.consultarSaldoCuentas();
  }

  consultarSaldoCuentas(){
    console.log("Consultando saldo de cuentas...");
    this.dispersionesService.consultarSaldo().subscribe(
      response => {
        console.log("informacion consultada con exito...");
        this.cuentas = response.body;
        if(this.cuentas != null && this.cuentas.length > 0){
          for(let dato of this.cuentas){
            dato.saldo = this.formatter1.format(Number(dato.saldo));
          }
        }
        console.log(this.cuentas);
      }, error => {
        console.log("Error al consultar informacion.");
      }
    );
  }

}
