import { Component, OnInit } from '@angular/core';

import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  nombreUsuario: string;
  nombrePerfil: string;
  localStorageChanges$ = this.localStorageService.changes$;

  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.nombreUsuario = this.localStorageService.get('nombreUsuario');
    this.nombrePerfil = this.localStorageService.get('nombrePerfil');
  }

}
