import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { Usuario } from 'src/app/models/usuario';
import { CommonMessagesService } from 'src/app/services/common-messages.service';
import { LoginService } from 'src/app/services/login.service';
import { CustomValidators } from 'src/app/validations/custom-validators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cambiar-contrasenia',
  templateUrl: './cambiar-contrasenia.component.html',
})
export class CambiarContraseniaComponent implements OnInit {

  public passwordActualizar = new Usuario();
  public idUsuario: string;
  public frmSignup: FormGroup;
  msj: string;
  msjToast: string;

  constructor(private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private confirmationService: ConfirmationService,
    private commonMsg: CommonMessagesService,
    private spinner: NgxSpinnerService) {
    this.frmSignup = this.createSignupForm();
    this.idUsuario = this.router.url.split("#")[1];
  }

  ngOnInit(): void {
  }

  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        password: [null, Validators.compose([Validators.required,
            CustomValidators.patternValidator(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$-.%/_&#])[A-Za-z\d@$-.%/_&#]{8,}$/, { hasEightWords: true })          
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // Si los dos password son iguales
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }
  
  submit() {
    // Mostrar datos recibidos de la forma
    this.spinner.show();   //Iniciar spinner
    this.passwordActualizar.idUsuario = this.router.url.split("#")[1];
    this.passwordActualizar.contrasenia = this.frmSignup.get('password').value;
    console.log('Datos para update :'+JSON.stringify(this.passwordActualizar));
    this.loginService.actualizarContrasenia(this.passwordActualizar).subscribe(
      data => {
        if (data.body==null){
          console.log('No se actualizo, la contraseña.');
          this.spinner.hide();   //Ocultar Spinner
          this.msjToast = 'No se actualizo, la contraseña. Intentelo de nuevo más tarde.!',
          this.commonMsg.crearMSgToast('error', 'No se actualizo, la contraseña.', this.msjToast);  
        }else if(data.body !=null && data.status===200){
          console.log('Update OK.');
          this.spinner.hide();   //Ocultar Spinner
          this.confirmationService.confirm({
            header: "Cambio de contraseña Exitoso",
            acceptLabel: "Si",
            rejectLabel: "No",
            message: '¿Desea regresar al login?',
            accept: () => {
              this.router.navigate(['./', 'login']);
            }
          });  
        }


      },
      err => {
        console.log('Update Error');
        this.spinner.hide();   //Ocultar Spinner
        this.msjToast = 'Servicio no disponible. Intentelo de nuevo más tarde.!',
        this.commonMsg.crearMSgToast('error', 'Error al actualizar la contraseña.', this.msjToast);
      }
    );

  }


  ventanaAlerta(icono: any, titulo: string, texto:string):void{
    Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      footer: '<a href="/login">'+titulo+'</a>'
    });
  }

}
