import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { GraficoDispersionesDia } from 'src/app/models/grafico-dispersiones-dia';
import { CommonMessagesService } from 'src/app/services/common-messages.service';
import { DispersionesService } from 'src/app/services/dispersiones.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ConstantsService } from 'src/app/util/constants.services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  //Ids Perfiles de Usuario

  idPerfilOperador: number = this.constantsService.ID_PERFIL_OPERADOR;
  idPerfilGerente: number = this.constantsService.ID_PERFIL_GERENTE;
  idPerfilGerenteRegional: number = this.constantsService.ID_PERFIL_GERENTE_REGIONAL;

  idPerfil: number;

  graficoDispersionesDia: GraficoDispersionesDia = new GraficoDispersionesDia();
  graficoDispersionesDiaSemana: GraficoDispersionesDia[] = new Array();

  graficaDia: any;
  graficaDiaChart: Chart;
  mostrarGraficaDia: boolean = true;
  mensajeGraficaDia: string = 'Sin información por el momento';

  graficaDiaSemana: any;
  graficaDiaSemanaChart: Chart;
  dataGraficaSemana: any;
  mostrarGraficaDiaSemana: boolean = true;
  mensajeGraficaDiaSemana: string = 'Sin información por el momento';
  totalDispersionesSemana: number = 0;
  totalDispersionesSemanaLiquidadas: number = 0;
  totalDispersionesSemanaRechazadas: number = 0;
  totalDispersionesSemanaProcesando: number = 0;
  dispersionesSemanaLiquidadas: number[] = new Array();
  dispersionesSemanaRechazadas: number[] = new Array();
  dispersionesSemanaProcesando: number[] = new Array();

  fechaActualFormato: string = '';
  fechaPrimerDiaSemanaFormato: string = '';
  fechaUltimoDiaSemanaFormato: string = '';
  mostrarRangoFecha: boolean = false;

  constructor(private constantsService: ConstantsService,
    private localStorageService: LocalStorageService,
    private spinner: NgxSpinnerService,
    private commonMsg: CommonMessagesService,
    private dispersionesService: DispersionesService) {
  }

  ngOnInit(): void {
    this.idPerfil = this.localStorageService.get("idPerfil");
    this.cargarDatosIniciales();
  }

  cargarDatosIniciales() {
    this.spinner.show();
    if (this.idPerfil === this.idPerfilOperador) {
      this.dispersionesService.getCantidadDispersionesByDiaAndEstadoInSemana().subscribe(
        response => {
          this.graficoDispersionesDiaSemana = response.body;
  
          this.graficoDispersionesDiaSemana.forEach(
            graficoDispersionesDia => {
              this.totalDispersionesSemana = this.totalDispersionesSemana + graficoDispersionesDia.dispersionesTotales;
              this.totalDispersionesSemanaLiquidadas = this.totalDispersionesSemanaLiquidadas + graficoDispersionesDia.dispersionesLiquidadas;
              this.totalDispersionesSemanaRechazadas = this.totalDispersionesSemanaRechazadas + graficoDispersionesDia.dispersionesRechazadas;
              this.totalDispersionesSemanaProcesando = this.totalDispersionesSemanaProcesando + graficoDispersionesDia.dispersionesProcesando;
              this.dispersionesSemanaLiquidadas.push(graficoDispersionesDia.dispersionesLiquidadas);
              this.dispersionesSemanaRechazadas.push(graficoDispersionesDia.dispersionesRechazadas);
              this.dispersionesSemanaProcesando.push(graficoDispersionesDia.dispersionesProcesando);
            }
          );
          if (this.totalDispersionesSemana != 0) {
            this.graficaDia = document.getElementById('graficaDiaSemana');
            this.graficaDiaChart = new Chart(this.graficaDia,
              {
                type: 'line',
                data: {
                  labels: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie'],
                  datasets: [
                    {
                      label: 'Liquidado',
                      data: this.dispersionesSemanaLiquidadas,
                      borderColor: 'rgba(18,146,9)',
                      borderWidth: 1,
                      fill: false
                    },
                    {
                      label: 'Procesando',
                      data: this.dispersionesSemanaProcesando,
                      borderColor: 'rgba(255, 152, 0)',
                      borderWidth: 1,
                      fill: false
                    },
                    {
                      label: 'Rechazada',
                      data: this.dispersionesSemanaRechazadas,
                      borderColor: 'rgba(255, 67, 54)',
                      borderWidth: 1,
                      fill: false
                    }
                  ]
                },
              }
            );
          } else {
            this.mostrarGraficaDiaSemana = false;
          }
          this.mostrarRangoFecha = true;
          this.fechaPrimerDiaSemanaFormato = this.graficoDispersionesDiaSemana[0].fecha;
          this.fechaUltimoDiaSemanaFormato = this.graficoDispersionesDiaSemana[this.graficoDispersionesDiaSemana.length - 1].fecha;
        }, error => {
          this.mostrarGraficaDiaSemana = false;
          this.mensajeGraficaDiaSemana = 'Servicio no disponible';
          console.log(error);
          this.spinner.hide();
          this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
        }
      );
    } 
    if (this.idPerfil === this.idPerfilOperador || this.idPerfil === this.idPerfilGerente) {
      this.dispersionesService.getCantidadDispersionesByDiaAndEstado().subscribe(
        response => {
          this.graficoDispersionesDia = response.body;
  
          if (this.graficoDispersionesDia.dispersionesTotales != 0) {
            this.graficaDia = document.getElementById('graficaDia');
            this.graficaDiaChart = new Chart(this.graficaDia,
              {
                type: 'pie',
                data: {
                  labels: ['Liquidado', 'Procesando', 'Rechazada'],
                  datasets: [{
                    data: [
                      this.graficoDispersionesDia.dispersionesLiquidadas,
                      this.graficoDispersionesDia.dispersionesProcesando,
                      this.graficoDispersionesDia.dispersionesRechazadas
                    ],
                    backgroundColor: [
                      'rgba(18,146,9)',
                      'rgba(255, 152, 0)',
                      'rgba(244, 67, 54)'
                    ],
                    borderColor: [
                      'rgba(18,146,9)',
                      'rgba(255, 152, 0)',
                      'rgba(244, 67, 54)'
                    ],
                    borderWidth: 1
                  }]
                },
              }
            );
          } else {
            this.mostrarGraficaDia = false;
          }
          this.fechaActualFormato = this.graficoDispersionesDia.fecha;
        }, error => {
          this.mostrarGraficaDia = false;
          this.mensajeGraficaDia = 'Servicio no disponible';
          console.log(error);
          this.spinner.hide();
          this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible');
        }
      );
    }
  }

}
