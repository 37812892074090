import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ConfirmationService, MessageService } from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class CommonMessagesService {

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService) { }


  confirmarPopup(event: Event, icono: any, msg: string): Promise<boolean> {
    return new Promise(resolve => {
      this.confirmationService.confirm({
        target: event.target,
        message: msg,
        icon: icono,
        acceptButtonStyleClass: 'btn-nom-success',
        rejectButtonStyleClass: 'btn-nom-warning',
        acceptLabel: 'Aceptar',
        rejectLabel: 'Cancelar',
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      })
    });
  }

  crearMSgToast(sever: string, summ: any, msg: string): void {
    this.messageService.add({
      severity: sever,
      summary: summ,
      detail: msg
    });
  }
}
