import { Injectable } from '@angular/core';
import {  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { tap } from "rxjs/operators";
//import 'rxjs/add/operator/do';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResponseInterceptorService  implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //console.log("disparando interceptor...");
    let request = req;
    let tipoUrl: boolean = false;   
    let url = request.url; 

    if(url.includes('/recibosDispersion/descargaReciboPdf')){
      //console.log("la url es de descarga de recibo");
      tipoUrl = true;
    }
    /*return next.handle(request).pipe(
      finalize(() => {
        
      })
    );*/

    /*return next.handle(req)
      .pipe(
        tap(
          (event: HttpEvent<any>) => {
            console.log("evento recibido....");
            console.log(event);
            
            if(event instanceof HttpResponse){
              console.log("respuesta correcta")
              if(event.status == 201 && event.headers.get("Content-Type") == 'application/json;charset=UTF-8'){
                console.log("estatus recibido 201");
              }
            }
          }, (error: HttpErrorResponse) => {
                console.log('error recibido: ' + error);

            if(error.status == 404){
              console.log('el estatus es 404 not_found');
              if(tipoUrl){
                console.log('El recibo de la dispersion no fue encontrado');
              }
            }
          }
        )
      );*/

      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) =>{
          console.log("error detectado...");
          if(error instanceof ErrorEvent){
            console.log(1);
          } else {
            // TODO: Clean up following by introducing method
            console.log(2);
          if (error.status === 498) { 
            // TODO: Destroy local session; redirect to /login
          }
          if (error.status === 401) { 
            // TODO: Permission denied; show toast
          }
          if (error.status === 404) { 
            // TODO: Permission denied; show toast
            console.log('recibo no encontrado...');
          }
          if (error.status === 500) { 
            // TODO: Destroy local session; redirect to /login
            console.log('recibo no encontrado 500...');
          }
          }
          return throwError(error);
        })
      );
  }
}
