import { Injectable } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class FechaService {

  fechaActual: Date = new Date();

  constructor() { }

  getFecha(model: NgbDateStruct): string {
    if (model != null) {
      let fechaFinal = '';
      let anio = model.year;
      let mes = model.month;
      let dia = model.day;

      if (dia >= 1 && dia <= 9) {
        fechaFinal = '0' + dia + '-';
      } else {
        fechaFinal = dia + '-';
      }

      if (mes >= 1 && mes <= 9) {
        fechaFinal = fechaFinal + '0' + mes;
      } else {
        fechaFinal = fechaFinal + '' + mes;
      }

      return fechaFinal + '-' + anio;
    } else {
      return null;
    }
  }

  getFechaActual(): string {
    return (this.fechaActual.getDate() < 10 ? '0' : '').concat(this.fechaActual.getDate().toString(), this.fechaActual.getMonth() < 9 ? '-0' : '-', (this.fechaActual.getMonth() + 1).toString(), '-', this.fechaActual.getFullYear().toString());
  }

  setFecha(fecha): string {
    fecha = new NgbDate(Number(fecha.substring(6, 10)), Number(fecha.substring(3, 5)), Number(fecha.substring(0,2)));
    return fecha;
  }

}