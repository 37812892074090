import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../../models/usuario';
import { LoginService } from '../../services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';    //Spinner
import { LocalStorageService } from '../../services/local-storage.service';
import * as CryptoJS from 'crypto-js';
import { ConstantsService } from 'src/app/util/constants.services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: [ConstantsService]
})

export class LoginComponent implements OnInit {
  public usuarioLogin: Usuario = new Usuario();

  //Ids Perfiles de Usuario

  idPerfilOperador: number = this.constantsService.ID_PERFIL_OPERADOR;
  idPerfilGerente: number = this.constantsService.ID_PERFIL_GERENTE;
  idPerfilGerenteRegional: number = this.constantsService.ID_PERFIL_GERENTE_REGIONAL;

  idPerfil: number;

  localStorageChanges$ = this.localStorageService.changes$;
  form: any = {};
  isLoginFailed = false;
  message: string = '';
  encryptSecretKey: string = 'Sup3R-S33cr#to-k';


  constructor(private loginService: LoginService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private constantsService: ConstantsService) { }

  ngOnInit(): void {
    this.localStorageService.removeAll();
  }


  async onSubmit(): Promise<void> {
    this.spinner.show();   //Iniciar spinner

    this.usuarioLogin.usuario = this.encryptData(this.usuarioLogin.usuario)
    this.usuarioLogin.contrasenia = this.encryptData(this.usuarioLogin.contrasenia)

    let respuestaLogin = await this.loginService.login(this.usuarioLogin).toPromise().catch(reason => {
      this.message = 'Servicio no disponible. Intentelo de nuevo más tarde.';
      this.isLoginFailed = true;
    }).finally(() => {
      this.limpiarCampos(this.usuarioLogin);
    });
    if (respuestaLogin.code === 1 && respuestaLogin.msg === "OK") {  //Activo
      this.spinner.hide();   //Ocultar Spinner
      this.idPerfil = respuestaLogin.perfil.idPerfil;
      this.localStorageService.set('idUsuario', respuestaLogin.usuario.idUsuario);
      this.localStorageService.set('nombreUsuario', respuestaLogin.usuario.nombre);
      this.localStorageService.set('idPerfil', respuestaLogin.perfil.idPerfil);
      this.localStorageService.set('nombrePerfil', respuestaLogin.perfil.nombre);
      this.localStorageService.set('token', respuestaLogin.token);
      if (this.idPerfil === this.idPerfilOperador || this.idPerfil === this.idPerfilGerente) {
        this.router.navigate(['./', 'dashboard']);
      } else {
        this.router.navigate(['./', 'dispersiones-realizadas']);
      }
      this.isLoginFailed = false;
    } else if (respuestaLogin.code === 0 && respuestaLogin.msg === "NOT_FOUND") {
      this.spinner.hide();   //Ocultar Spinner
      this.message = 'Usuario y/o password incorrecto.';
      this.isLoginFailed = true;
    } else {
      this.spinner.hide();   //Ocultar Spinner
      this.isLoginFailed = true;
      this.message = 'Servicio no disponible. Intentelo de nuevo más tarde.';
    }

    this.limpiarCampos(this.usuarioLogin);
  }

  limpiarCampos(usuarioObj: Usuario): void {
    usuarioObj.usuario = "";
    usuarioObj.contrasenia = "";
  }

  reloadPage(): void {
    window.location.reload();
  }

  encryptData(word) {
    var key = CryptoJS.enc.Utf8.parse(this.encryptSecretKey);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
  }

}