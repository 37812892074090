<div class="modal-header">
    <h5 class="modal-title">Resumen de descarga de CEPs</h5>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <p-card>
                <div class="row">
                    <div class="col-8">Seleccionados</div>
                    <div class="col-4">{{totalAllDispersionSeleccionada}}</div>
                </div>
                <div class="row">
                    <div class="col-8">Con CEP</div>
                    <div class="col-4">{{totalAllDispersionConCEPSeleccionada}}</div>
                </div>
                <div class="row">
                    <div class="col-8">CEP no encontrado</div>
                    <div class="col-4">{{totalAllDispersionSinCEPSeleccionada}}</div>
                </div>
                <div class="row">
                    <div class="col-8">CEP no aplica</div>
                    <div class="col-4">{{totalAllDispersionNoAplicaCEPSeleccionada}}</div>
                </div>
            </p-card>
        </div>
    </div>
</div>
<div class="col-12 modal-footer">
    <button type="button" class="btn btn-nom-success" (click)="cerrar(true)">Descargar</button>
    <button type="button" class="btn btn-nom-danger" data-dismiss="modal" data-backdrop="false"
        (click)="cerrar(false)">Cancelar</button>
</div>