import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPopupModule } from "primeng/confirmpopup";
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { ToastModule } from "primeng/toast";
import {DropdownModule} from 'primeng/dropdown';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { ConfirmationService, MessageService } from "primeng/api";
import {InputTextModule} from 'primeng/inputtext';
import {ContextMenuModule} from 'primeng/contextmenu';
import {MultiSelectModule} from 'primeng/multiselect';
//import {SliderModule} from 'primeng/slider';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { DefaultComponent } from 'src/app/components/layout/default/default.component';
import { AuthGuard } from './guards/auth.guard.guard';
import { LoginComponent } from './components/login/login.component';
import { DispersionesComponent } from './components/dispersiones/dispersiones.component';
import { RecuperarContraseniaComponent } from './components/recuperar-contrasenia/recuperar-contrasenia.component';
import { CambiarContraseniaComponent } from './components/cambiar-contrasenia/cambiar-contrasenia.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FechaService } from './util/fecha-service';
import { HeaderInterceptor } from './interceptors/header-interceptor';
import { OperacionesComponent } from './components/operaciones/operaciones.component';
import { ComisionesPagadasModalComponent } from './components/dispersiones/comisiones-pagadas-modal.component';
import { CustomDatepickerI18nService, I18n } from './services/custom-datepicker-i18n.service';
import { ResumenDescargaCepsModalComponent } from './components/dispersiones/resumen-descarga-ceps-modal.component';
import { FechaFormato } from './util/fecha-formato.service';
import { SortService } from './util/sort.service';
import { ConstantsService } from './util/constants.services';
import { CargaEmpleadosComponent } from './components/carga-empleados/carga-empleados.component';
import { LogCargaEmpleadosComponent } from './components/log-carga-empleados/log-carga-empleados.component';
import { AdministracionCatalogosComponent } from './components/administracion-catalogos/administracion-catalogos.component';
import { DepartamentosFormModalComponent } from './components/administracion-catalogos/departamentos-form-modal.component';
import { MensajeModalComponent } from './components/utils/mensaje-modal/mensaje-modal.component';
import { PreguntaModalComponent } from './components/utils/pregunta-modal/pregunta-modal.component';
import { CentrosCostosFormModalComponent } from './components/administracion-catalogos/centros-costos-form-modal.component';
import { CentrosTrabajoFormModalComponent } from './components/administracion-catalogos/centros-trabajo-form-modal.component';
import { EnvioComisionComponent } from './components/envio-comision/envio-comision.component';
import { ReprocesoAvanzadoModalComponent } from './components/dispersiones/reproceso-avanzado-modal.component';
import { ResponseInterceptorService } from './interceptors/response-interceptor.service';
import { ConsultaSaldoComponent } from './components/consulta-saldo/consulta-saldo.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'recuperar-contrasenia', component: RecuperarContraseniaComponent},
  {path: 'cambiar-contrasenia', component: CambiarContraseniaComponent},
  {path: '', component: DefaultComponent,
             children: [{path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
                        {path: 'dispersiones-realizadas', component: DispersionesComponent, canActivate: [AuthGuard]},
                        {path: 'operaciones', component: OperacionesComponent, canActivate: [AuthGuard]},
                        {path: 'carga-empleados', component: CargaEmpleadosComponent, canActivate: [AuthGuard]},
                        {path: 'envio-comision', component: EnvioComisionComponent, canActivate: [AuthGuard]},
                        {path: 'saldo-cuentas', component: ConsultaSaldoComponent, canActivate: [AuthGuard]},
                        // {path: 'log-carga-empleados', component: LogCargaEmpleadosComponent, canActivate: [AuthGuard]},
                        // {path: 'administracion-catalogos', component: AdministracionCatalogosComponent, canActivate: [AuthGuard]}
                      ]
                    }];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    HeaderComponent,
    DefaultComponent,
    DispersionesComponent,
    RecuperarContraseniaComponent,
    CambiarContraseniaComponent,
    DashboardComponent,
    OperacionesComponent,
    ComisionesPagadasModalComponent,
    ResumenDescargaCepsModalComponent,
    CargaEmpleadosComponent,
    LogCargaEmpleadosComponent,
    AdministracionCatalogosComponent,
    DepartamentosFormModalComponent,
    MensajeModalComponent,
    PreguntaModalComponent,
    CentrosCostosFormModalComponent,
    CentrosTrabajoFormModalComponent,
    EnvioComisionComponent,
    ReprocesoAvanzadoModalComponent,
    ConsultaSaldoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    TableModule,
    ButtonModule,
    BrowserAnimationsModule,
    ChartsModule,
    NgxPaginationModule,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    NgbModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    ToastModule,
    DropdownModule,
    AutoCompleteModule,
    InputTextModule,
    ContextMenuModule,
    MultiSelectModule,
    //SliderModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ConfirmationService, MessageService, FechaService, SortService, ConstantsService,
    DatePipe, { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptorService, multi: true},
    {provide: NgbDateParserFormatter, useClass: FechaFormato},
    I18n,{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService}],
  bootstrap: [AppComponent]
})
export class AppModule { }
