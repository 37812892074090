<div class="container-fluid h-100 background-dis">
    <div class="row">
        <div class="col-12 h-100">
            <div class="row">
                <div class="col-sm-12">
                </div>
            </div>
            <div class="container">
                <!-- Row -->
                <div class="table-struct w-100">
                    <div class="table-cell auth-form-wrap">
                        <div class="auth-form mx-auto form-content">
                            <div class="col-12">
                                <img src="/../assets/img/logo-cane.svg" class="img-fluid logo-login d-block mx-auto">
                            </div>
                            <div class="mb-30">
                                <h5 class="text-center mb-30">Ingresar</h5>
                            </div>
                            <div class="form-wrap">
                                <ngx-spinner bdColor="rgba(5,0,248,0.8)" size="default" color="#fff" type="ball-spin"
                                    [fullScreen]="false">
                                    <p style="color: white"> Cargando... </p>
                                </ngx-spinner>
                                <form name="form" id="formLogin" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm"
                                    class="needs-validation" novalidate>
                                    <div class="form-group">
                                        <label for="user">*Usuario</label>

                                        <input type="text" class="form-control" name="nombreUsuario" id="usuario"
                                            [(ngModel)]="usuarioLogin.usuario" required #nombreUsuario="ngModel"
                                            placeholder="Ingresa tu usuario">
                                        <div *ngIf="isLoginFailed">
                                            {{ message }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="password">*Contraseña</label>
                                        <a class="d-block pull-right" href="./recuperar-contrasenia">¿Olvidaste tu
                                            contraseña?</a>
                                        <input type="password" class="form-control" name="contrasenia" id="contrasenia"
                                            [(ngModel)]="usuarioLogin.contrasenia" required minlength="6"
                                            #contrasenia="ngModel" placeholder="Ingresa tu contraseña">

                                        <div *ngIf="isLoginFailed">
                                            {{ message }}
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <button class="btn btn-nom-content" type="submit"
                                            (click)="onSubmit">Ingresar</button>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <p class="text-white">Versión 1.0</p>
            </div>
        </div>
    </div>