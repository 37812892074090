import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ NoPasswordMatch: true });
    }
  }

  static validate(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      let fecha = control.value;

      let mes = fecha.month;
      let dia = fecha.day;
      let fechaFinal = fecha.year + "-";

      if (mes >= 1 && mes <= 9) {
        fechaFinal = fechaFinal + "0" + mes + "-";
      } else {
        fechaFinal = fechaFinal + "" + mes + "-";
      }

      if (dia >= 1 && dia <= 9) {
        fechaFinal = fechaFinal + "0" + dia;
      } else {
        fechaFinal = fechaFinal + "" + dia;
      }

      let fechanacimiento = moment(fechaFinal, "YYYY-MM-DD");
      let years = moment().diff(fechanacimiento, 'years');

      return years >= 18 ? null : error;
    };
  }

  static validateFecha(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      let fecha = control.value;

      if (fecha != "") {
        let mes = fecha.month;
        let dia = fecha.day;
        let fechaFinal = fecha.year + "-";

        if (mes >= 1 && mes <= 9) {
          fechaFinal = fechaFinal + "0" + mes + "-";
        } else {
          fechaFinal = fechaFinal + "" + mes + "-";
        }

        if (dia >= 1 && dia <= 9) {
          fechaFinal = fechaFinal + "0" + dia;
        } else {
          fechaFinal = fechaFinal + "" + dia;
        }

        let fechanacimiento = moment(fechaFinal, "YYYY-MM-DD");
        let years = moment().diff(fechanacimiento, 'years');

        return years == 0 ? null : error;
      } else {
        return null
      }
    };
  }

  /**
   * Valida que campos estan marcados como invalidos en tu form
   */
  public findInvalidControls() {
    const invalid = [];
    /*const controls = this.formEmpresa.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
            console.log(name);
        }
    }*/
    return invalid;
  }

  /**
   * 
   * Funcion para validar el formato de la fecha
   */
  static validateFechaFormato(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      let fecha = control.value;

      if (fecha != "") {
        console.log("llamanado validacion de formato de fecha");
        let mes = fecha.month;
        let dia = fecha.day;
        let fechaFinal = fecha.year + "-";

        if (mes >= 1 && mes <= 9) {
          fechaFinal = fechaFinal + "0" + mes + "-";
        } else {
          fechaFinal = fechaFinal + "" + mes + "-";
        }

        if (dia >= 1 && dia <= 9) {
          fechaFinal = fechaFinal + "0" + dia;
        } else {
          fechaFinal = fechaFinal + "" + dia;
        }

        //let fechanacimiento = moment(fechaFinal, "YYYY-MM-DD");
        //let years = moment().diff(fechanacimiento, 'years');
        console.log("fecha obtenida en la validacion: " + fechaFinal);

        //var regExp = new RegExp('(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])');
        var regExp = new RegExp(/^(\d{4})[-](0[1-9]|1[0-2])[-]([0-2][0-9]|3[0-1])$/);
        const valid = regExp.test(fechaFinal);
        console.log("Valor de validacion: " + valid);

        return valid ? null : error;
      } else {
        return null
      }
    };
  }

  /**
   * 
   * Funcion para validar el que la fecha ingresada manualmente cumpla con la restriccion
   * 1 mes menor a la fecha actual o 1 mes mayor a la fecha actual
   */
   static validateFechaManual(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      let fecha = control.value;

      if (fecha != "") {
        console.log("llamanado validacion de rango de fecha valida");
        let mes = fecha.month;
        let dia = fecha.day;
        let fechaFinal = fecha.year + "-";

        let fechaEvaluar = new Date(fecha.year, mes-1, dia);

        //console.log("fecha ingresada a evaluar: ");
        //console.log(fechaEvaluar);

        let fechas: Date[] = [], minFecha: Date, maxFecha: Date, date: Date = new Date();
  if(date.getMonth() === 0){
    if(date.getDate() === 1) {
      minFecha = new Date(date.getFullYear() - 1, 11, date.getDate() + 1);
      maxFecha = new Date(date.getFullYear(), 0, date.getDate() + 30);
    } else if(date.getDate() === 31) {
      minFecha = new Date(date.getFullYear(), 0, 1);
      maxFecha = new Date(date.getFullYear(), 2, date.getFullYear() % 4 === 0 ? 1 : 2);
    } else {
      minFecha = new Date(date.getFullYear() - 1, 11, date.getDate() + 1);
      maxFecha = new Date(date.getFullYear(), 1, date.getDate() - 1);
    }
  } else if(date.getMonth() === 11){
    if(date.getDate() === 1){
      minFecha = new Date(date.getFullYear(), 10, date.getDate());
      maxFecha = new Date(date.getFullYear(), 11, 31);
    } else if (date.getDate() === 31){
      minFecha = new Date(date.getFullYear(), 11, 1);
      maxFecha = new Date(date.getFullYear() + 1, 0, date.getDate() - 1);
    } else {
      minFecha = new Date(date.getFullYear(), 10, date.getDate());
      maxFecha = new Date(date.getFullYear() + 1, 0, date.getDate() - 1);
    }
  } else {
    let mesMin: Date = new Date(date.getFullYear(), date.getMonth(), 0), 
    mesMax: Date = new Date(date.getFullYear(), date.getMonth() + 2, 0),
    mesAct: Date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    if( date.getDate() === 1 ){
      minFecha = new Date(date.getFullYear(), mesMin.getMonth() === 1 ? 0 : mesMin.getMonth(), mesMin.getDate() === 31 ?  2 : mesMin.getDate() === 30 ? 1 : mesMin.getFullYear() % 4 ? 30 : 31);
      maxFecha = new Date(date.getFullYear(), mesAct.getDate() === 31 ? mesAct.getMonth() : mesMax.getMonth(), mesAct.getDate() === 31 ? 31 : mesAct.getDate() === 30 ? 1 : mesMin.getFullYear() % 4 === 0 ? 2 : 3);
    } else if(date.getDate() === 31 ) {
      minFecha = new Date(date.getFullYear(), mesAct.getMonth(), 1);
      maxFecha = new Date(date.getFullYear(), mesMax.getMonth(), 30);
    } else if (date.getDate() === 30 ){
      minFecha = new Date(date.getFullYear(), mesMin.getMonth(), mesMin.getDate());
      maxFecha = new Date(date.getFullYear(), mesMax.getMonth(), mesAct.getDate() === 31 ? 29 : 30);
    } else if (date.getDate() === 29 ){
      minFecha = new Date(date.getFullYear(), mesMin.getMonth(), mesMin.getDate() === 31 ? 30 : mesMin.getDate() === 30 ? 29 : mesMin.getFullYear() % 4 === 0 ? 28 : 27);
      maxFecha = new Date(date.getFullYear(), mesMax.getMonth(), mesAct.getDate() === 31 ? 28 : mesAct.getDate() === 30 ? 29 : 30);
    } else {
      minFecha = new Date(date.getFullYear(), mesMin.getFullYear() % 4 != 0 && date.getDate() === 2 && mesMin.getMonth() === 1 ? 0 : mesMin.getMonth(), mesMin.getDate() === 31 ? date.getDate() + 1 : mesMin.getDate() === 30 ? date.getDate() : mesMin.getFullYear() % 4 === 0 ? date.getDate() - 1 : date.getDate() === 2 ? 31 : date.getDate() - 2);
      maxFecha = new Date(date.getFullYear(), mesMax.getMonth(), mesAct.getDate() === 31 ? date.getDate() - 1 : mesAct.getDate() === 30 ? date.getDate() : mesMin.getFullYear() % 4 ? date.getDate() + 1 : date.getDate() + 2);
    }
  }
  fechas.push(minFecha);
  fechas.push(maxFecha);

  //console.log(minFecha);
  //console.log(maxFecha);

  let valid = false;
       if(fechaEvaluar >= minFecha && fechaEvaluar <= maxFecha){
         valid = true;
         //console.log('validacion correcta la fecha esta dentro del rango');
       }
       else{
         //console.log('la validacion fallo la fecha esta fuera del rango');
       }

        return valid == true ? null : error;
      } else {
        return null
      }
    };
  }

}