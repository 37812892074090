import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dispersion } from 'src/app/models/dispersion';
import { Reproceso } from 'src/app/models/reproceso';
import { CommonMessagesService } from 'src/app/services/common-messages.service';
import { DispersionesService } from 'src/app/services/dispersiones.service';
import { CustomValidators } from 'src/app/validations/custom-validators';

@Component({
  selector: 'app-reproceso-avanzado-modal',
  templateUrl: './reproceso-avanzado-modal.component.html'
})
export class ReprocesoAvanzadoModalComponent implements OnInit {

  @Input() idDispersion: number;

  public reprocesoAvanzadoModalForm: FormGroup;
  public showCampoMetodoPago: boolean = false;

  // Campos del formulario

  public CAMPO_TIPO_REPROCESO = 'tipoReproceso';
  public CAMPO_METODO_PAGO = 'metodoPago';

  public CAMPO_METODO_PAGO_MAX_LENGTH = 30;

  // Tipos de reproceso

  public TIPO_REPROCESO_STP_BAJA = 1;
  public TIPO_REPROCESO_OTRO = 2;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private commonMsg: CommonMessagesService,
    private spinner: NgxSpinnerService,
    private dispersionService: DispersionesService
  ) {
    this.reprocesoAvanzadoModalForm = this.createReprocesoAvanzadoModalForm();
  }

  ngOnInit(): void {
  }

  createReprocesoAvanzadoModalForm(): FormGroup {
    return this.formBuilder.group({
      tipoReproceso: [null, Validators.compose([
        Validators.required,
        Validators.min(this.TIPO_REPROCESO_STP_BAJA),
        Validators.max(this.TIPO_REPROCESO_OTRO)
      ])],
      metodoPago: [{ value: null, disabled: true }, null]
    });
  }

  onChangeTipoReproceso(): void {
    const tipoReproceso: number = this.reprocesoAvanzadoModalForm.get(this.CAMPO_TIPO_REPROCESO).value;
    this.showCampoMetodoPago = tipoReproceso == this.TIPO_REPROCESO_OTRO;
    this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).setValue(null);
    if (this.showCampoMetodoPago) {
      this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).setValidators(Validators.compose([
        Validators.required,
        Validators.maxLength(this.CAMPO_METODO_PAGO_MAX_LENGTH),
        CustomValidators.patternValidator(/^[\w\s]{1,30}$/, { isInvalidPatternMetodoPago: true })
      ]));
      this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).enable({ onlySelf: true });
      this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).updateValueAndValidity();
    } else {
      this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).clearValidators();
      this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).disable({ onlySelf: true });
      this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).updateValueAndValidity();
    }
  }

  public updateReprocesoAvanzado() {
    let tipoToast: string = '', tituloToast: string = '', mensajeToast: string = '';
    if (this.reprocesoAvanzadoModalForm.valid) {
      this.spinner.show();
      let reproceso: Reproceso = new Reproceso();
      reproceso.idDispersion = this.idDispersion;
      reproceso.tipoReproceso = this.reprocesoAvanzadoModalForm.get(this.CAMPO_TIPO_REPROCESO).value;
      reproceso.metodoPago = this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).value;
      this.dispersionService.getReprocesoDispersion(reproceso).subscribe(response => {
        tipoToast = 'info';
        tituloToast = 'Correcto';
        mensajeToast = 'Petición de reprocesamiento enviada exitosamente.';
        this.spinner.hide();
        this.commonMsg.crearMSgToast(tipoToast, tituloToast, mensajeToast);
      }, error => {
        tipoToast = 'error';
        tituloToast = 'Intente de nuevo más tarde';
        mensajeToast = 'Servicio no disponible.';
        console.log(error);
        this.spinner.hide();
        this.commonMsg.crearMSgToast(tipoToast, tituloToast, mensajeToast);
      });
      this.activeModal.close();
    } else {
      tipoToast = 'error';
      if (this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).hasError('isInvalidPatternMetodoPago')) {
        tituloToast = 'Formulario erroneo';
        mensajeToast = 'El campo de Metodo de pago contiene caracteres invalidos';
      }
      if (this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).hasError('maxLength')) {
        tituloToast = 'Formulario erroneo';
        mensajeToast = 'El campo de Metodo de pago solo permite ' + this.CAMPO_METODO_PAGO_MAX_LENGTH + ' caracteres';
      }
      if (this.reprocesoAvanzadoModalForm.get(this.CAMPO_METODO_PAGO).hasError('required')) {
        tituloToast = 'Formulario incompleto';
        mensajeToast = 'El campo de Metodo de pago es obligatorio';
      }
      if (this.reprocesoAvanzadoModalForm.get(this.CAMPO_TIPO_REPROCESO).hasError('required')) {
        tituloToast = 'Formulario incompleto';
        mensajeToast = 'Se debe seleccionar un Tipo de reproceso';
      }
      this.commonMsg.crearMSgToast(tipoToast, tituloToast, mensajeToast);
    }
  }

  public cancelar(): void {
    this.activeModal.close();
  }

}
