<div class="modal-header">
    <h5 class="modal-title">Agregar centro costo</h5>
</div>
<div class="modal-body">
    <form name="form" id="formCentroCosto" [formGroup]="formCentroCosto">
        <div>
            <label for="nombre">Nombre</label>
            <input class="form-control mb-3" type="text" aria-label="Nombre" formControlName="nombre"
                maxlength="100" autofocus>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelar()">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="guardarCentroCosto()">Guardar</button>
</div>