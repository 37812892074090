<div class="container-fluid h-100 background-dis">
    <div class="row">
        <div class="col-12 h-100">
            <div class="row">
                <div class="col-sm-12">
                </div>
            </div>
            <div class="container">
                <!-- Row -->
                <div class="table-struct w-100">
                    <div class="table-cell auth-form-wrap pb-120">
                        <div class="auth-form mx-auto form-content">
                            <div class="col-12">
                                <img src="/../assets/img/logo-cane.svg" class="img-fluid logo-login d-block mx-auto">
                            </div>
                            <div class="mb-30">
                                <h5 class="text-center mb-30">Nueva contraseña</h5>
                            </div>
                            <div class="form-wrap">
                                <ngx-spinner bdColor = "rgba(5,0,248,0.8)" size = "default" color = "#fff" type = "ball-spin" [fullScreen] = "false">
                                    <p style="color: white" > Cargando... </p>
                                  </ngx-spinner>
                                  <p-confirmPopup autoZIndex="false" baseZIndex="1"></p-confirmPopup> 
                                  <p-toast position="bottom-right"></p-toast>
                                  <p-confirmDialog header="Confirmation" icon="pi pi-check"></p-confirmDialog>
                                <form [formGroup]="frmSignup" (submit)="submit()">
                                    <div class="form-group">
                                        <label for="password"[ngClass]="frmSignup.controls['password'].invalid ? '' : ''">Ingresa tu nueva contraseña</label>
                                        <div class="input-group" id="show_hide_password">
                                            <input id="password" 
                                                name="password"
                                                formControlName="password" 
                                                type="password"
                                                class="form-control" 
                                                [ngClass]="frmSignup.controls['password'].invalid ? 'is-invalid' : ''"
                                                placeholder="Ingresa tu nueva contraseña">
                                            <div class="input-group-addon">
                                                <a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                            <label class="col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasEightWords') ? 'text-danger' :'text-success'">
                                                <i class="material-icons">
                                                        {{ frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasEightWords') ? 'cancel' : 'check_circle' }}
                                                </i>Minimo 8 caracteres, al menos una letra mayúscula, al menos una letra minúscula, al menos un número y al menos un caracter especial.
                                            </label>
                                    </div>
                                    <div class="form-group">
                                        <label for="confirmPassword" [ngClass]="frmSignup.controls['confirmPassword'].invalid ? '' : ''">Confirmar contraseña</label>
                                        <div class="input-group" id="show_hide_password">
                                            <input id="confirmPassword" 
                                                formControlName="confirmPassword" 
                                                type="password"
                                                class="form-control" 
                                                [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'is-invalid' : ''"
                                                placeholder="Confirmar contraseña">
                                            <div class="input-group-addon">
                                                <a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                            <label class="col text-danger" *ngIf="frmSignup.controls['confirmPassword'].hasError('required') || frmSignup.controls['confirmPassword'].hasError('NoPasswordMatch')">
                                                <i class="material-icons reset">
                                                    {{ frmSignup.controls['password'].hasError('required') || frmSignup.controls['confirmPassword'].hasError('NoPasswordMatch') ? 'cancel' : 'check_circle' }}
                                                </i>Las contraseñas no coinciden, por favor verifiquelas.
                                            </label>
                                    </div>
                                    <div class="form-group">
                                        <button [disabled]="frmSignup.invalid" class="btn btn-nom-content btn-rounded" type="submit">Confirmar</button>
                                    </div>                            
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="text-white">Versión 1.0</p>
                <!--/Row-->
            </div>
        </div>
    </div>
</div>