import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ConstantsService } from 'src/app/util/constants.services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  providers: [ConstantsService]
})
export class SidebarComponent implements OnInit {

  configuracion : boolean;
  
  bolOpciones: boolean = false;
  bolOpcion1: boolean = false;
  bolOpcion2: boolean = false;

  //Ids Perfiles de Usuario

  idPerfilOperador: number = this.constantsService.ID_PERFIL_OPERADOR;
  idPerfilGerente: number = this.constantsService.ID_PERFIL_GERENTE;
  idPerfilGerenteRegional: number = this.constantsService.ID_PERFIL_GERENTE_REGIONAL;

  idPerfil: number;

  constructor(private constantsService: ConstantsService,
    private localStorageService: LocalStorageService){
  }

  ngOnInit(): void {
    this.idPerfil = this.localStorageService.get("idPerfil");
    this.getMenuSuperUser();
  }

  getMenuSuperUser(){
    this.bolOpciones = true;
    this.bolOpcion1 = true;
    this.bolOpcion2 = true;
  }

}
 