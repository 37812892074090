<div class="wrapper d-flex">
    <div class="page-wrapper">
        <div class="row title-wraper">
            <div class="col-12 title-page-fd">
                <h2>Log empleados</h2>
            </div>
        </div>
        <div class="row content-wrapper-inner mt-2" *ngIf="logCargaEmpleadosList.length === 0; else existenRegistros">
            <div class="col-2"></div>
            <div class="col-8">
                <h6> Última ejecución: Sin información por el momento</h6>
            </div>
            <div class="col-2"></div>
        </div>
        <ng-template #existenRegistros>
            <div class="row content-wrapper-inner mt-2">
                <div *ngFor="let logCargaEmpleados of logCargaEmpleadosList; index as i; first as isFirst">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <h6 *ngIf="isFirst; else esNoPrimerRegistro"><b>Última ejecución: {{logCargaEmpleados.fechaHora}}</b></h6>
                            <ng-template #esNoPrimerRegistro><h6><b>Ejecución: {{logCargaEmpleados.fechaHora}}</b></h6></ng-template>
                        </div>
                        <div class="col-2"></div>
                    </div>
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8" [innerHTML]="logCargaEmpleados.logCargaEmpleados">
                        </div>
                        <div class="col-2"></div>
                    </div>
                    <hr style="margin-top: 20px;">
                </div>
            </div>
        </ng-template>
    </div>
</div>