<div class="wrapper d-flex">
    <div class="page-wrapper">
        <main>
            <div class="row title-wraper">
                <div class="col-12 title-page-fd">
                    <h2>Dispersiones Realizadas</h2>
                </div>
            </div>
            <div class="row content-wrapper-inner container-lavels-fd">
                <div *ngIf="idPerfil === idPerfilGerenteRegional" class="col-12 col-sm-9 col-md-9 lavel-fd">
                    <div class="row">
                        <div class="col-12 col-sm-3 col-md-3">
                            <label for="dispersionesDia">Dispersiones del día</label>
                        </div>
                        <div class="col-12 col-sm-9 col-md-9"></div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-3 col-md-3">
                            <input id="dispersionesDia" class="form-control" type="text" disabled>
                        </div>
                        <div class="col-12 col-sm-9 col-md-9"></div>
                    </div>
                </div>
                <div *ngIf="mostrarGrafica; else mostrarMensajeGraficoDia" class="col-12 col-sm-3 lavel-fd">
                    <p-card>
                        <div class="row">
                            <div class="col-9">
                                <i class="fa fa-square liquidadas" aria-hidden="true" style="padding-right: 10px;"></i>
                                <label for="liquidadas">Liquidadas</label>
                            </div>
                            <div class="col-3"><strong>{{cantidadesDispersiones.dispersionesLiquidadas}}</strong></div>
                        </div>

                        <div class="row">
                            <div class="col-9">
                                <i class="fa fa-square procesando" aria-hidden="true" style="padding-right: 10px;"></i>
                                <label for="procesando">Procesando</label>
                            </div>
                            <div class="col-3"><strong>{{cantidadesDispersiones.dispersionesProcesando}}</strong></div>
                        </div>

                        <div class="row">
                            <div class="col-9">
                                <i class="fa fa-square rechazadas" aria-hidden="true" style="padding-right: 10px;"></i>
                                <label for="rechazadas">Rechazadas</label>
                            </div>
                            <div class="col-3"><strong>{{cantidadesDispersiones.dispersionesRechazadas}}</strong></div>
                        </div>
                        <div class="row">
                            <div class="col-9">
                                <label for="total">Total</label>
                            </div>
                            <div class="col-3"><strong>{{cantidadesDispersiones.dispersionesTotales}}</strong></div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p>Total colocación: <strong>{{totalColocacion}}</strong></p>
                                <p>Total comisión: <strong>{{totalComision}}</strong></p>
                            </div>
                        </div>
                    </p-card>
                </div>
                <ng-template #mostrarMensajeGraficoDia>
                    <div class="col-12 col-sm-3 lavel-fd">
                        <p>{{mensajeGrafica}}</p>
                    </div>
                </ng-template>
                <div *ngIf="idPerfil === idPerfilOperador || idPerfil === idPerfilGerente"
                    class="col-12 col-sm-7 lavel-fd">
                    <form class="needs-validation" novalidate [formGroup]="formFiltrosDispersiones">
                        <div class="row">
                            <div class="col-12 col-sm-6 mb-1" *ngIf="idPerfil === idPerfilOperador">
                                <select class="form-control" formControlName="idBeneficiarioTipo">
                                    <option [ngValue]="-1">Tipo Empleado</option>
                                    <option [ngValue]="beneficiarioTipo.idBeneficiarioTipo"
                                        *ngFor="let beneficiarioTipo of beneficiarioTipos">
                                        {{beneficiarioTipo.nombre}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-sm-6 mb-1">
                                <select class="form-control" formControlName="idCatDepartamento">
                                    <option [ngValue]="-1">Departamento</option>
                                    <option [ngValue]="departamento.idCatDepartamento"
                                        *ngFor="let departamento of departamentos">
                                        {{departamento.nombre}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-sm-6 mb-1">
                                <select class="form-control" formControlName="idCatCentroCostos">
                                    <option [ngValue]="-1">Centro costos</option>
                                    <option [ngValue]="centroCostos.idCatCentroCosto"
                                        *ngFor="let centroCostos of centrosCostos">
                                        {{centroCostos.nombre}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-sm-6 mb-1">
                                <select class="form-control" formControlName="idCatCentroTrabajo">
                                    <option [ngValue]="-1">Centro trabajo</option>
                                    <option [ngValue]="centroTrabajo.idCatCentroTrabajo"
                                        *ngFor="let centroTrabajo of centrosTrabajo">
                                        {{centroTrabajo.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-6 mb-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="Desde" size="9" name="fechaDesde"
                                        formControlName="fechaDesde" aria-describedby="inputGroupPrepend" readonly
                                        ngbDatepicker #di="ngbDatepicker">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend" (click)="di.toggle();">
                                            <a id="edit">
                                                <i class="fa fa-calendar-o edit-register"></i>
                                            </a>
                                        </span>
                                    </div>
                                    <div class="invalid-feedback">
                                        Fecha no puede estar vacia
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-6 mb-1">
                                <div class="input-group">
                                    <input class="form-control" placeholder="Hasta" size="9" name="fechaHasta"
                                        formControlName="fechaHasta" aria-describedby="inputGroupPrepend" readonly
                                        ngbDatepicker
                                        [minDate]="formFiltrosDispersiones.get('fechaDesde').value != null && formFiltrosDispersiones.get('fechaDesde').valid ?
                                                            { day: formFiltrosDispersiones.get('fechaDesde').value.day, month: formFiltrosDispersiones.get('fechaDesde').value.month, year: formFiltrosDispersiones.get('fechaDesde').value.year} 
                                                            : { day: hoy.getDate(), month: hoy.getMonth() + 1, year: hoy.getFullYear()}"
                                        [ngClass]="formEnviar && ((formFiltrosDispersiones.get('fechaHasta').value === null && formFiltrosDispersiones.get('fechaHasta').value != null) || formFiltrosDispersiones.get('fechaHasta').invalid) ? 'is-invalid' : ''"
                                        #df="ngbDatepicker">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend" (click)="df.toggle();">
                                            <a id="edit">
                                                <i class="fa fa-calendar-o edit-register"></i>
                                            </a>
                                        </span>
                                    </div>
                                    <div class="invalid-feedback">
                                        <p
                                            *ngIf="formFiltrosDispersiones.get('fechaHasta').value === null && formFiltrosDispersiones.get('fechaDesde').value != null">
                                            Fecha no puede estar vacia
                                        </p>
                                        <p *ngIf="formFiltrosDispersiones.controls['fechaHasta'].hasError('ngbDate')">
                                            La fecha es menor a la otra
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-6"></div>
                        </div>
                    </form>
                </div>
                <div *ngIf="idPerfil === idPerfilOperador || idPerfil === idPerfilGerente"
                    class="col-12 col-sm-2 button-fd">
                    <button type="submit" class="btn btn-nom-content" data-target="#modal-legal-carga"
                        (click)="consultar(true)">Consultar
                    </button>
                    <button type="submit" class="btn btn-nom-content" (click)="descargarCEPsChecked(false)"
                        data-target="#modal-legal-carga">Descargar todos los CEPs
                    </button><!--style="display:none"-->
                    <button type="submit" class="btn btn-nom-content" *ngIf="idPerfil === idPerfilOperador"
                        (click)="changeDispersionesTipoPagoTnnToLiquidadas()" data-target="#modal-legal-carga">
                        Cambio status Alquimia</button>
                </div>
            </div>
            <div class="row content-wrapper-inner mt-2">
    
                <!-- Tabla para el perfil de Operador -->
                <ng-template [ngIf]="idPerfil === idPerfilOperador">
                    <div class="table-responsive table-bordered beneficios-empleado mt-4">
                        <p-table #table dataKey="id" [value]="dispersiones" [rows]="10" [customSort]="true"
                            (sortFunction)="customSort($event)" [showCurrentPageReport]="true"
                            [rowsPerPageOptions]="[10,20,30]" styleClass="p-datatable-sm" [paginator]="true"
                            [alwaysShowPaginator]="mostrarPaginador"
                            currentPageReportTemplate="Resultado: {first} - {last} de {totalRecords}"
                            [globalFilterFields]="['empresa', 'numeroEmpleado', 'nombreEmpleado', 'dispersion', 'monto', 'fechaColocacion', 'fechaPago', 'estatus', 'fechaEstatus']">
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="12" class="text-center">{{mensajeTabla}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr class="head-top">
                                    <th>
                                        <!--Vacio para el Check general-->
                                    </th>
                                    <th pSortableColumn="numeroEmpleado"># Empleado<p-sortIcon field="numeroEmpleado">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="nombreEmpleado">Nombre empleado<p-sortIcon
                                            field="nombreEmpleado">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="empresa">Empresa <p-sortIcon field="empresa">
                                        </p-sortIcon>
                                     </th>
                                    <th pSortableColumn="dispersion">Dispersión<p-sortIcon field="dispersion">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="monto">Monto comisión<p-sortIcon field="monto">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="montoColocado">Monto colocado<p-sortIcon field="montoColocado">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="fechaColocacion">Fecha colocación<p-sortIcon
                                            field="fechaColocacion">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="fechaPago">Fecha Pago<p-sortIcon field="fechaPago">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="estatus">Estatus<p-sortIcon field="estatus">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="fechaEstatus">Fecha Estatus<p-sortIcon field="fechaEstatus">
                                        </p-sortIcon>
                                    </th>
                                    <th>CEP</th>
                                    <th>REP</th>
                                </tr>
                                <tr class="head-bottom">
                                    <th>
                                        <input type="checkbox" id="checkAll" name="check"
                                            (change)="changeAllCheckBox()">
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="numeroEmpleado"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="nombreEmpleado"></p-columnFilter>
                                    </th>
                                    <th>  
                                        <p-columnFilter type="text" field="empresa"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="dispersion"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="monto"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="montoColocado"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="fechaColocacion"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="fechaPago"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="estatus"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="fechaEstatus"></p-columnFilter>
                                    </th>
                                    <th>
                                        <!--Vacio para CEP-->
                                    </th>
                                    <th>
                                        <!--Vacio para REP-->
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-dispersion>
                                <tr class="ng-star-inserted">
                                    <th>
                                        <input type="checkbox" style="cursor: pointer" title="Seleccionar"
                                            [checked]="dispersion.checked"
                                            (change)="changeCheckBox($event, dispersion.idDispersion)">
                                    </th>
                                    <td>{{dispersion.numeroEmpleado}}</td>
                                    <td>{{dispersion.nombreEmpleado}}</td>
                                    <td>{{dispersion.empresa}}</td> 
                                    <td>{{dispersion.dispersion}}</td>
                                    <td>{{dispersion.monto}}</td>
                                    <td>{{dispersion.montoColocado}}</td>
                                    <td>{{dispersion.fechaColocacion}}</td>
                                    <td>{{dispersion.fechaPago}}</td>
                                    <td class="{{ dispersion.estatus == 'Procesando' ? dispersion.estatus.toLowerCase() : dispersion.estatus.toLowerCase()+'s' }}">
                                        <p *ngIf="dispersion.estatus != 'Liquidada'">
                                            <span  ngbTooltip= "{{dispersion.descripcionStatus}}">
                                                {{dispersion.estatus}}
                                            </span>
                                        </p>
                                        <p *ngIf="dispersion.estatus == 'Liquidada'">
                                            {{dispersion.estatus}}
                                        </p>
                                    </td>
                                    <td>
                                        {{dispersion.fechaEstatus}}
                                    </td>
                                    <td class="actions-table text-center align-middle">
                                        <!--<a *ngIf="dispersion.cep" id="descargar" style="cursor: pointer"
                                            data-toggle="modal" data-target="#" title="Descargar CEP"><i
                                                class="state-active ti-download"
                                                (click)="descargarCEPsChecked(true, dispersion.idDispersion)"></i></a>-->
                                                <p *ngIf="dispersion.estatus != 'Procesando'">
                                                    <a *ngIf="dispersion.estatusCep == 'Listo' && dispersion.dispersion != 'TNN'" id="descargar" href="{{urlDownloadPdf}}/{{dispersion.idDispersion}}" title="Descargar CEP"><i
                                                        class="state-active ti-download"></i></a>
                                                    <span *ngIf="dispersion.estatusCep == 'Pendiente' && dispersion.dispersion != 'TNN'" id="descargar" title="Descargar CEP"><i
                                                        class="state-active ti-time"></i></span>
                                                </p>
                                    </td>

                                    <td class="actions-table text-center align-middle">
                                        <a *ngIf="dispersion.estatus == 'Rechazada' && dispersion.numeroEmpleado != '0'" id="reprocesar"  style="cursor: pointer" (click)=reprocesarDis(dispersion.idDispersion) title="Reprocesar">
                                            <i class="state-active ti-reload"></i>
                                        </a>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-template>
                <!-- Tabla para el perfil de Gerente -->
                <ng-template [ngIf]="idPerfil === idPerfilGerente">
                    <div class="table-responsive table-bordered beneficios-empleado mt-4">
                        <p-table #table dataKey="id" [value]="dispersiones" [rows]="10" [customSort]="true"
                            (sortFunction)="customSort($event)" [showCurrentPageReport]="true"
                            [rowsPerPageOptions]="[10,20,30]" styleClass="p-datatable-sm" [paginator]="true"
                            [alwaysShowPaginator]="mostrarPaginador"
                            currentPageReportTemplate="Resultado: {first} - {last} de {totalRecords}"
                            [globalFilterFields]="['nombreEmpresa', 'numeroEmpleado', 'nombreEmpleado', 'dispersion', 'monto', 'fechaEstatus', 'estatus']">
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="9" class="text-center">{{mensajeTabla}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr class="head-top">
                                    <th>
                                        <!--Vacio para el Check general-->
                                    </th>
                                    <th style="display: none;" pSortableColumn="nombreEmpresa">Empresa <p-sortIcon field="nombreEmpresa">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="numeroEmpleado"># Empleado<p-sortIcon field="numeroEmpleado">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="nombreEmpleado">Nombre empleado<p-sortIcon
                                            field="nombreEmpleado">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="dispersion">Dispersión<p-sortIcon field="dispersion">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="monto">Monto comisión<p-sortIcon field="monto">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="montoColocado">Monto colocado<p-sortIcon field="montoColocado">
                                        </p-sortIcon>
                                    </th>
                                    
                                    <th pSortableColumn="estatus">Estatus<p-sortIcon field="estatus">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="fechaEstatus">Fecha Estatus<p-sortIcon field="fechaEstatus">
                                        </p-sortIcon>
                                    </th>
                                    <th>CEP</th>
                                </tr>
                                <tr class="head-bottom">
                                    <th>
                                        <input type="checkbox" id="checkAll" name="check"
                                            (change)="changeAllCheckBox()">
                                    </th>
                                    <th style="display: none;">
                                        <p-columnFilter type="text" field="nombreEmpresa"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="numeroEmpleado"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="nombreEmpleado"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="dispersion"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="monto"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="montoColocado"></p-columnFilter>
                                    </th>
                    
                                    <th>
                                        <p-columnFilter type="text" field="estatus"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="fechaEstatus"></p-columnFilter>
                                    </th>
                                    <th>
                                        <!--Vacio para CEP-->
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-dispersion>
                                <tr class="ng-star-inserted">
                                    <th>
                                        <input type="checkbox" style="cursor: pointer" title="Seleccionar"
                                            [checked]="dispersion.checked"
                                            (change)="changeCheckBox($event, dispersion.idDispersion)">
                                    </th>
                                    <td>{{dispersion.numeroEmpleado}}</td>
                                    <td>{{dispersion.nombreEmpleado}}</td>
                                    <td>{{dispersion.dispersion}}</td>
                                    <td>{{dispersion.monto}}</td>
                                    <td>{{dispersion.montoColocado}}</td>
 
                                    <td class="{{ dispersion.estatus == 'Procesando' ? dispersion.estatus.toLowerCase() : dispersion.estatus.toLowerCase()+'s' }}">
                                        <p *ngIf="dispersion.estatus != 'Liquidada'">
                                            <span  ngbTooltip= "{{dispersion.descripcionStatus}}">
                                                {{dispersion.estatus}}
                                            </span>
                                        </p>
                                        <p *ngIf="dispersion.estatus == 'Liquidada'">
                                            {{dispersion.estatus}}
                                        </p>
                                    </td>
                                    <td>
                                        {{dispersion.fechaEstatus}}
                                    </td>
                                    <td class="actions-table text-center align-middle">
                                        <!--<a *ngIf="dispersion.cep" id="descargar" style="cursor: pointer"
                                            data-toggle="modal" data-target="#" title="Descargar CEP"><i
                                                class="state-active ti-download"
                                                (click)="descargarCEPsChecked(true, dispersion.idDispersion)"></i></a>-->
                                                <p *ngIf="dispersion.estatus != 'Procesando'">
                                                    <a *ngIf="dispersion.estatusCep == 'Listo' && dispersion.dispersion != 'TNN'" id="descargar" href="{{urlDownloadPdf}}/{{dispersion.idDispersion}}" title="Descargar CEP"><i
                                                        class="state-active ti-download"></i></a>
                                                    <span *ngIf="dispersion.estatusCep == 'Pendiente' && dispersion.dispersion != 'TNN'" id="descargar" title="Descargar CEP"><i
                                                        class="state-active ti-time"></i></span>
                                                </p>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-template>
                <!-- Tabla para el perfil de Gerente Regional -->
                <ng-template [ngIf]="idPerfil === idPerfilGerenteRegional">
                    <div class="table-responsive table-bordered beneficios-empleado mt-4">
                        <p-table #table dataKey="id" [value]="dispersiones" [rows]="10" [customSort]="true"
                            (sortFunction)="customSort($event)" [showCurrentPageReport]="true"
                            [rowsPerPageOptions]="[10,20,30]" styleClass="p-datatable-sm" [paginator]="true"
                            [alwaysShowPaginator]="mostrarPaginador"
                            currentPageReportTemplate="Resultado: {first} - {last} de {totalRecords}"
                            [globalFilterFields]="['nombreEmpresa', 'numeroEmpleado', 'nombreEmpleado', 'dispersion', 'monto', 'fechaEstatus', 'estatus']">
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7" class="text-center">{{mensajeTabla}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr class="head-top">
                                    <th>
                                        <!--Vacio para el Check general-->
                                    </th>
                                    <th style="display: none;" pSortableColumn="nombreEmpresa">Empresa <p-sortIcon field="nombreEmpresa">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="numeroEmpleado"># Empleado<p-sortIcon field="numeroEmpleado">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="nombreEmpleado">Nombre empleado<p-sortIcon
                                            field="nombreEmpleado">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="dispersion">Dispersión<p-sortIcon field="dispersion">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="monto">Monto comisión<p-sortIcon field="monto">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="montoColocado">Monto colocado<p-sortIcon field="montoColocado">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="estatus">Estatus<p-sortIcon field="estatus">
                                        </p-sortIcon>
                                    </th>
                                </tr>
                                <tr class="head-bottom">
                                    <th>
                   
                                    </th>
                                    <th style="display: none;">
                                        <p-columnFilter type="text" field="nombreEmpresa"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="numeroEmpleado"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="nombreEmpleado"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="dispersion"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="monto"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="montoColocado"></p-columnFilter>
                                    </th>
                                    <th>
                                        <p-columnFilter type="text" field="estatus"></p-columnFilter>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-dispersion>
                                <tr class="ng-star-inserted">
                                    <td></td>
                                    <td>{{dispersion.numeroEmpleado}}</td>
                                    <td>{{dispersion.nombreEmpleado}}</td>
                                    <td>{{dispersion.dispersion}}</td>
                                    <td>{{dispersion.monto}}</td>
                                    <td>{{dispersion.montoColocado}}</td>
                                    <td class="{{ dispersion.estatus == 'Procesando' ? dispersion.estatus.toLowerCase() : dispersion.estatus.toLowerCase()+'s' }}">
                                        <p *ngIf="dispersion.estatus != 'Liquidada'">
                                            <span  ngbTooltip= "{{dispersion.descripcionStatus}}">
                                                {{dispersion.estatus}}
                                            </span>
                                        </p>
                                        <p *ngIf="dispersion.estatus == 'Liquidada'">
                                            {{dispersion.estatus}}
                                        </p>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-template>
            </div>

            <ngx-spinner bdColor = "rgba(5,0,248,0.8)" size = "default" color = "#fff" type = "ball-spin" fullScreen="true">
                <p style="color: black"> Cargando... </p>
            </ngx-spinner>


        </main>
    </div>
</div>