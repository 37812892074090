<div class="modal-header">
    <h5 class="modal-title">{{tituloModal}}</h5>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <p style="text-align: center;"><b>{{mensajeModal}}</b></p>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="container">
        <div class="row">
            <div class="col-sm"></div>
            <div class="col-sm"></div>
            <div class="col-sm">
                <button type="button" class="btn btn-nom-content" (click)="activeModal.close()">Aceptar</button>
            </div>
            <div class="col-sm"></div>
            <div class="col-sm"></div>
        </div>
    </div>
</div>