import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Dispersion } from 'src/app/models/dispersion';

@Component({
  selector: 'app-resumen-descarga-ceps-modal',
  templateUrl: './resumen-descarga-ceps-modal.component.html'
})
export class ResumenDescargaCepsModalComponent implements OnInit {

  @Input() dispersiones: Dispersion[] = new Array();

  totalAllDispersionSeleccionada: number = 0;
  totalAllDispersionConCEPSeleccionada: number = 0;
  totalAllDispersionSinCEPSeleccionada: number = 0;
  totalAllDispersionNoAplicaCEPSeleccionada: number = 0;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.countAllDispersionByCEP();
  }

  countAllDispersionByCEP() {
    this.dispersiones.forEach(dispersion => {
      if (dispersion.cep != null) {
        if (dispersion.cep) {
          this.totalAllDispersionConCEPSeleccionada++;
        } else {
          this.totalAllDispersionSinCEPSeleccionada++;
        }
      } else {
        this.totalAllDispersionNoAplicaCEPSeleccionada++;
      }
      this.totalAllDispersionSeleccionada++;
    });
  }

  cerrar(isDescargar: boolean) {
    let idDispersionList: number[] = new Array();
    if (isDescargar) {
      this.dispersiones.forEach(dispersion => {
        if (dispersion.cep) {
          idDispersionList.push(dispersion.idDispersion);
        }
      });
      this.activeModal.close(idDispersionList);
    } else {
      this.activeModal.dismiss();
    }
  }

}
