import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrimeNGConfig, SortEvent } from 'primeng/api';
import { Observable, Subscription, timer } from 'rxjs';
import { Table } from 'primeng/table';
import { BeneficiarioTipo } from 'src/app/models/benficiario-tipo';
import { CatCentroCosto } from 'src/app/models/cat-centro-costo';
import { CatCentroTrabajo } from 'src/app/models/cat-centro-trabajo';
import { CatDepartamento } from 'src/app/models/cat-departamento';
import { Dispersion } from 'src/app/models/dispersion';
import { DispersionesFiltrosRequest } from 'src/app/models/dispersiones-filtros-request';
import { GraficoDispersionesDia } from 'src/app/models/grafico-dispersiones-dia';
import { CommonMessagesService } from 'src/app/services/common-messages.service';
import { DispersionesService } from 'src/app/services/dispersiones.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ConstantsService } from 'src/app/util/constants.services';
import { FechaService } from 'src/app/util/fecha-service';
import { SortService } from 'src/app/util/sort.service';
import { ComisionesPagadasModalComponent } from './comisiones-pagadas-modal.component';
import { ResumenDescargaCepsModalComponent } from './resumen-descarga-ceps-modal.component';
import { ReprocesoAvanzadoModalComponent } from './reproceso-avanzado-modal.component';

@Component({
  selector: 'app-dispersiones',
  templateUrl: './dispersiones.component.html',
  providers: [ConstantsService]
})
export class DispersionesComponent implements OnInit {

  //Ids Perfiles de Usuario

  idPerfilOperador: number = this.constantsService.ID_PERFIL_OPERADOR;
  idPerfilGerente: number = this.constantsService.ID_PERFIL_GERENTE;
  idPerfilGerenteRegional: number = this.constantsService.ID_PERFIL_GERENTE_REGIONAL;

  idUsuario: number;
  idPerfil: number;

  dispersionesFiltrosRequest: DispersionesFiltrosRequest = new DispersionesFiltrosRequest();
  formFiltrosDispersiones: FormGroup;
  hoy: Date;
  formEnviar: boolean = false;

  dispersiones: Dispersion[] = new Array();
  @ViewChild(Table, { static: false }) table: Table;
  mensajeTabla: string = '';
  mostrarPaginador: boolean = false;
  totalColocacion: string;
  totalComision: string;

  cantidadesDispersiones: GraficoDispersionesDia = new GraficoDispersionesDia();
  mostrarGrafica: boolean = true;
  mensajeGrafica: string = '';

  habilitarBotonCambiarEstatusTNN: boolean = false;
  habilitarBotonCEPs: boolean = false;

  //Catalogos

  beneficiarioTipos: BeneficiarioTipo[] = new Array();
  departamentos: CatDepartamento[] = new Array();
  centrosCostos: CatCentroCosto[] = new Array();
  centrosTrabajo: CatCentroTrabajo[] = new Array();

  modalOptions: NgbModalOptions;

  idsDescarga: any = {
    idsRecibos: []
  }

  urlDownloadPdf: string;

  subscription: Subscription;
  repetirConsulta: Observable<number>;// = timer(30000, 30000);
  repeticionActiva: boolean = false;
  hizoConsulta: boolean = false;
  tiempoRepeticion: number = 0;

  constructor(private constantsService: ConstantsService,
    private spinner: NgxSpinnerService,
    private config: PrimeNGConfig,
    private localStorageService: LocalStorageService,
    private dispersionesService: DispersionesService,
    private commonMsg: CommonMessagesService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fechaService: FechaService,
    private sortService: SortService) {
    this.hoy = fechaService.fechaActual;
    this.modalOptions = {
      backdrop: 'static',
      centered: true
    }
  }

  ngOnInit(): void {
    this.idPerfil = this.localStorageService.get("idPerfil");
    this.idUsuario = this.localStorageService.get("idUsuario");
    this.config.setTranslation({
      startsWith: 'Empieza con',
      contains: 'Contiene',
      notContains: 'No contiene',
      endsWith: 'Finaliza con',
      equals: 'Igual',
      notEquals: 'No es igual',
      noFilter: 'Sin Filtro'
    });
    this.urlDownloadPdf = this.constantsService.DESCARGA_RECIBO_DISPERSION;
    if (this.idPerfil === this.idPerfilOperador || this.idPerfil === this.idPerfilGerente) {
      this.createForm();
    }
    this.obtenerDatosIniciales();
  }

  private createForm() {
    this.formFiltrosDispersiones = this.formBuilder.group({
      idBeneficiarioTipo: [-1, null],
      idCatDepartamento: [-1, null],
      idCatCentroCostos: [-1, null],
      idCatCentroTrabajo: [-1, null],
      fechaDesde: [this.fechaService.setFecha(this.fechaService.getFechaActual()), Validators.required],
      fechaHasta: [this.fechaService.setFecha(this.fechaService.getFechaActual()), Validators.required]
    });
  }

  ngOnDestroy() {
    console.log("metodo onDestroy...");
    this.repetirConsulta = null;

    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

  obtenerDatosIniciales() {
    //this.spinner.show();
    if (this.idPerfil === this.idPerfilOperador) {
      this.dispersionesService.getAllBeneficiarioTipo().subscribe(
        response => {
          this.beneficiarioTipos = response.body;
        }, error => {
          console.log(error);
          this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
        }
      );
    }
    if (this.idPerfil === this.idPerfilOperador || this.idPerfil === this.idPerfilGerente) {
      this.dispersionesService.getAllDepartamento().subscribe(
        response => {
          this.departamentos = response.body;
        }, error => {
          console.log(error);
          this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
        }
      );
      this.dispersionesService.getAllCentroCostos().subscribe(
        response => {
          this.centrosCostos = response.body;
        }, error => {
          console.log(error);
          this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
        }
      );
      this.dispersionesService.getAllCentroTrabajo().subscribe(
        response => {
          this.centrosTrabajo = response.body;
        }, error => {
          console.log(error);
          this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
        }
      );
    }
    /*this.dispersionesService.getTiempoRepeticionConsulta().subscribe(response => {
      console.log(response.body);
      this.tiempoRepeticion = response.body * 1000;
      console.log(this.tiempoRepeticion);
      this.repetirConsulta = timer(this.tiempoRepeticion, this.tiempoRepeticion);
    }, error => {
      console.log("error al consultar tiempo para repeticion de consulta");
    });*/
    this.obtenerDispercionesOfDia();
  }

  obtenerDispercionesOfDia() {
    this.dispersionesService.getAllDispersionByDia(this.idUsuario).subscribe(response => {
      this.cantidadesDispersiones = response.body.cantidadesDispersiones;
      this.totalComision = response.body.totalComision;
      this.totalColocacion = response.body.totalColocacion;
      if (this.repeticionActiva) {
        let dispersiones: Dispersion[] = response.body.dispersiones;
        dispersiones.forEach(dispersion => {
          let indexDispersion: number = this.dispersiones.findIndex(d => d.idDispersion == dispersion.idDispersion);
          if(indexDispersion == -1){
            this.dispersiones.push(dispersion);
          } else {
            if (this.dispersiones[indexDispersion].empresa != dispersion.empresa || this.dispersiones[indexDispersion].numeroEmpleado != dispersion.numeroEmpleado ||
              this.dispersiones[indexDispersion].nombreEmpleado != dispersion.nombreEmpleado || this.dispersiones[indexDispersion].dispersion != dispersion.dispersion ||
              this.dispersiones[indexDispersion].monto != dispersion.monto || this.dispersiones[indexDispersion].montoColocado != dispersion.montoColocado ||
              this.dispersiones[indexDispersion].fechaColocacion != dispersion.fechaColocacion || this.dispersiones[indexDispersion].fechaPago != dispersion.fechaPago ||
              this.dispersiones[indexDispersion].estatus != dispersion.estatus || this.dispersiones[indexDispersion].fechaEstatus != dispersion.fechaEstatus ||
              this.dispersiones[indexDispersion].val != dispersion.val || this.dispersiones[indexDispersion].cep != dispersion.cep || this.dispersiones[indexDispersion].urlCep != dispersion.urlCep ||
              this.dispersiones[indexDispersion].estatusCep != dispersion.estatusCep || this.dispersiones[indexDispersion].descripcionStatus != dispersion.descripcionStatus) {
              this.dispersiones[indexDispersion] = dispersion;
            }
          }
        });
      } else {
        this.dispersiones = response.body.dispersiones;
      }
      if (this.dispersiones.length === 0) {
        this.mostrarGrafica = false;
        this.mensajeTabla = 'Sin información.';
        this.mensajeGrafica = 'Sin información.';
      }
      this.mostrarPaginador = this.dispersiones.length > 5 ? true : false;
      if (this.idPerfil === this.idPerfilGerenteRegional) {
        const campoDispersionesDelDia = document.getElementById('dispersionesDia') as HTMLInputElement;
        campoDispersionesDelDia.value = this.cantidadesDispersiones.fecha;
      }
      //this.llamarRepeticionActiva();
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.mostrarGrafica = false;
      this.mensajeTabla = 'Servicio no disponible.';
      this.mensajeGrafica = 'Servicio no disponible.';
      this.spinner.hide();
      this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
    });
  }

  changeAllCheckBox() {
    const checkBox = document.getElementById('checkAll') as HTMLInputElement;
    this.dispersiones.forEach(dispersion => {
      dispersion.checked = checkBox.checked;
    });
    if (this.idPerfil === this.idPerfilOperador || this.idPerfil === this.idPerfilGerente) {
      if (this.idPerfil === this.idPerfilOperador) {
        this.habilitarBotonCambiarEstatusTNN = this.dispersiones.filter(dispersion => dispersion.checked && dispersion.val).length > 0;
      }
      this.habilitarBotonCEPs = this.dispersiones.filter(dispersion => dispersion.checked /*&& dispersion.cep*/).length > 0;
    }
  }

  changeCheckBox(event: Event, idDispersion: number) {
    console.log('consultando funcion para checked...');
    const checkBox = event.target as HTMLInputElement;
    this.dispersiones.forEach(item => {
      if (item.idDispersion === idDispersion) {
        console.log('ids coinciden se setea true');
        item.checked = checkBox.checked;
        console.log(item.checked);
      }
    });
    const checkBoxAll = document.getElementById('checkAll') as HTMLInputElement;
    checkBoxAll.checked = this.dispersiones.every(dispersion => dispersion.checked);
    if (this.idPerfil === this.idPerfilOperador || this.idPerfil === this.idPerfilGerente) {
      if (this.idPerfil === this.idPerfilOperador) {
        this.habilitarBotonCambiarEstatusTNN = this.dispersiones.filter(dispersion => dispersion.checked && dispersion.val === false).length > 0;
      }
      this.habilitarBotonCEPs = this.dispersiones.filter(dispersion => dispersion.checked /*&& dispersion.cep*/).length > 0;
    }
  }

  getDataForm() {
    this.dispersionesFiltrosRequest.idUsuario = this.idUsuario;
    this.dispersionesFiltrosRequest.idBeneficiarioTipo = this.formFiltrosDispersiones.get('idBeneficiarioTipo').value === -1 ? null : this.formFiltrosDispersiones.get('idBeneficiarioTipo').value;
    this.dispersionesFiltrosRequest.idCatDepartamento = this.formFiltrosDispersiones.get('idCatDepartamento').value === -1 ? null : this.formFiltrosDispersiones.get('idCatDepartamento').value;
    this.dispersionesFiltrosRequest.idCatCentroCostos = this.formFiltrosDispersiones.get('idCatCentroCostos').value === -1 ? null : this.formFiltrosDispersiones.get('idCatCentroCostos').value;
    this.dispersionesFiltrosRequest.idCatCentroTrabajo = this.formFiltrosDispersiones.get('idCatCentroTrabajo').value === -1 ? null : this.formFiltrosDispersiones.get('idCatCentroTrabajo').value;
    this.dispersionesFiltrosRequest.fechaDesde = this.fechaService.getFecha(this.formFiltrosDispersiones.get('fechaDesde').value);
    this.dispersionesFiltrosRequest.fechaHasta = this.fechaService.getFecha(this.formFiltrosDispersiones.get('fechaHasta').value);
  }

  consultar(esManual: boolean) {
    this.formEnviar = true;
    this.hizoConsulta = true;
    this.spinner.show();
    if (this.formFiltrosDispersiones.valid) {
      const checkBoxAll = document.getElementById('checkAll') as HTMLInputElement;
      checkBoxAll.checked = false;
      this.getDataForm();
      if (!this.repeticionActiva || esManual) {
        this.dispersiones = [];
        this.mostrarGrafica = false;
        this.totalComision = '$0.00';
        this.totalColocacion = '$0.00';
      }
      this.dispersionesService.getAllDispersionByFiltros(this.dispersionesFiltrosRequest).subscribe(response => {
        this.cantidadesDispersiones = response.body.cantidadesDispersiones;
        this.totalComision = response.body.totalComision;
        this.totalColocacion = response.body.totalColocacion;
        if (esManual) {
          this.dispersiones = response.body.dispersiones;
        } else {
          let dispersiones: Dispersion[] = response.body.dispersiones;
          dispersiones.forEach(dispersion => {
            let indexDispersion: number = this.dispersiones.findIndex(d => d.idDispersion == dispersion.idDispersion);
            if(indexDispersion == -1){
              this.dispersiones.push(dispersion);
            } else {
              if (this.dispersiones[indexDispersion].empresa != dispersion.empresa || this.dispersiones[indexDispersion].numeroEmpleado != dispersion.numeroEmpleado ||
                this.dispersiones[indexDispersion].nombreEmpleado != dispersion.nombreEmpleado || this.dispersiones[indexDispersion].dispersion != dispersion.dispersion ||
                this.dispersiones[indexDispersion].monto != dispersion.monto || this.dispersiones[indexDispersion].montoColocado != dispersion.montoColocado ||
                this.dispersiones[indexDispersion].fechaColocacion != dispersion.fechaColocacion || this.dispersiones[indexDispersion].fechaPago != dispersion.fechaPago ||
                this.dispersiones[indexDispersion].estatus != dispersion.estatus || this.dispersiones[indexDispersion].fechaEstatus != dispersion.fechaEstatus ||
                this.dispersiones[indexDispersion].val != dispersion.val || this.dispersiones[indexDispersion].cep != dispersion.cep || this.dispersiones[indexDispersion].urlCep != dispersion.urlCep ||
                this.dispersiones[indexDispersion].estatusCep != dispersion.estatusCep || this.dispersiones[indexDispersion].descripcionStatus != dispersion.descripcionStatus) {
                this.dispersiones[indexDispersion] = dispersion;
              }
            }
          });
        }
        if (this.dispersiones.length === 0) {
          this.mostrarGrafica = false;
          this.mensajeTabla = 'No hay registros que coincidan con la consulta...';
          this.mensajeGrafica = 'Sin información.'
        } else {
          this.mostrarGrafica = true;
        }
        this.mostrarPaginador = this.dispersiones.length > 5 ? true : false;
        //this.llamarRepeticionActiva();
        this.spinner.hide();
      }, error => {
        this.mostrarGrafica = false;
        console.log(error);
        this.mensajeTabla = 'Servicio no disponible.';
        this.mensajeGrafica = 'Servicio no disponible.';
        this.spinner.hide();
        this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
      });
    }
  }

  llamarRepeticionActiva() {
    if (!this.repeticionActiva) {
      this.subscription = this.repetirConsulta.subscribe(() => {
        console.log("consultando cada 30 segundos ....");
        this.repeticionActiva = true;
        if (this.hizoConsulta) {
          this.consultar(false);
        } else {
          this.obtenerDispercionesOfDia();
        }
      });
    }
  }

  descargarCEPsChecked(isIndividual: boolean, idDispersion?: number) {
    console.log("llamando funcion de descarga masiva....");
    let idDispersionList: number[] = new Array();
    if (isIndividual) {
      idDispersionList.push(idDispersion);
      this.descargarCEPs(idDispersionList);
    } else {
      console.log('se procede a entrar al else...');
      this.dispersiones.forEach(dispersion => {
        if (dispersion.checked) {
          console.log("checked en tru encontrado....");
          idDispersionList.push(dispersion.idDispersion);
        }
      });
      console.log('tamanño de lista de ids: ' + idDispersionList.length + ' estatus de boton: ' + this.habilitarBotonCEPs);

      if (idDispersionList.length > 0 && this.habilitarBotonCEPs) {
        /*const modalResumenDescarga = this.modalService.open(ResumenDescargaCepsModalComponent, this.modalOptions);
        modalResumenDescarga.componentInstance.dispersiones = this.dispersiones.filter(dispersion => dispersion.checked);
        modalResumenDescarga.result.then(
          response => {
            idDispersionList = response;
            this.descargarCEPs(idDispersionList);
          }
        ).catch(
          error => {
            console.log(error);
          }
        );*/
        this.idsDescarga.idsRecibos = idDispersionList;
        console.log('tamanio de la lista de idsd: ' + this.idsDescarga.idsRecibos.length);
        this.descargarRecibos();
      } else {
        this.commonMsg.crearMSgToast('error', 'Falló', 'Esta acción requiere registros seleccionados');
      }
    }
  }

  reprocesarDis(idDispersion?: number) {
    const modalReprocesoAvanzadoForm = this.modalService.open(ReprocesoAvanzadoModalComponent, this.modalOptions);
    modalReprocesoAvanzadoForm.componentInstance.idDispersion = idDispersion;
    modalReprocesoAvanzadoForm.result.then((respuesta) => {}).catch((error) => {
      console.log(error);
      this.commonMsg.crearMSgToast('error', 'Falló', 'No se puede Reprocesar, intentelo más tarde.');
    }); 
  }

  descargarCEPs(idDispersionList: number[]) {
    this.dispersionesService.getAllArchivoCEP(this.idUsuario, idDispersionList).subscribe(response => {
      let urlCEP = "";
      this.dispersiones.forEach(
        dispersion => {
          if (dispersion.idDispersion == idDispersionList[0]) {
            urlCEP = dispersion.urlCep;
          }
        }
      )
      //let contentDisposition = response.headers.get('content-disposition');
      //let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      //let file = new Blob([response.body], { type: response.body.type });
      let link = document.createElement('a');
      link.href = urlCEP;
      link.target = "_blank";
      //link.download = filename;
      link.click();
    }, error => {
      console.log(error);
      this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
    });
  }

  changeDispersionesTipoPagoTnnToLiquidadas() {
    let idDispersionList: number[] = new Array();
    if (this.dispersiones.filter(dispersion => dispersion.checked).length > 0 && this.habilitarBotonCambiarEstatusTNN) {
      const modalCambioEstatus = this.modalService.open(ComisionesPagadasModalComponent, this.modalOptions);
      modalCambioEstatus.componentInstance.dispersiones = this.dispersiones.filter(dispersion => dispersion.checked);
      modalCambioEstatus.result.then(
        response => {
          idDispersionList = response;
          this.dispersionesService.changeDispersionesTipoPagoTnnToLiquidadas(this.idUsuario, idDispersionList).subscribe(
            response => {
              if (response.body) {
                this.dispersiones.forEach(
                  dispersion => {
                    if (dispersion.val === false && idDispersionList.includes(dispersion.idDispersion)) {
                      dispersion.val = true;
                    }
                  }
                );
                this.commonMsg.crearMSgToast('info', 'Exitoso', 'Los registros cambiaron de estatus a Liquidada');
                this.consultar(false);
              } else {
                this.commonMsg.crearMSgToast('error', 'Falló', 'Los registros no cambiaron de estatus a Liquidada');
              }
            }, error => {
              console.log(error);
              this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
            }
          );
        }
      ).catch(
        error => {
          console.log(error);
        }
      );
    } else {
      this.commonMsg.crearMSgToast('error', 'Falló', 'Esta acción requiere registros seleccionados');
    }
  }

  customSort(event: SortEvent) {
    this.sortService.ordenarExistenFechas(event);
  }


  descargarRecibos(): void {
    console.log('llamando funcion para descarga de recibos en zip');
    this.spinner.show();
    console.log('tamaño de la lista a enviar: ' + this.idsDescarga.idsRecibos.length);
    this.idsDescarga.idsRecibos.forEach(id => {
      console.log('id a enviar: ' + id);
    });
    this.dispersionesService.getRecibosDispersiones(this.idsDescarga).subscribe(response => {
      console.log(response);
      this.spinner.hide();
      let data = this.base64ToArrayBuffer(response.file);
      let blob = new Blob([data], { type: 'application/zip' });
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = response.nombre;
      downloadLink.click();

    }, error => {
      console.log(error);
      this.spinner.hide();
      console.log('error al descargar archivo...');
    })
  }

  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

}
