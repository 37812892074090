import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DatosBusqueda } from "../models/datos-busqueda";
import { DispersionesFiltrosRequest } from "../models/dispersiones-filtros-request";
import { ConstantsService } from "../util/constants.services";
import { EnvioComision } from "../models/envio-comision";
import { Reproceso } from "../models/reproceso";

@Injectable({
    providedIn: 'root'
})
export class DispersionesService {
    private constantsServices: ConstantsService = new ConstantsService();

    constructor(private http: HttpClient) { }

    getAllDispersionByDia(idUsuario: number): Observable<any>{
        return this.http.get(this.constantsServices.DISPERSIONES_API + '/' + idUsuario, {observe: 'response'});
    }

    getAllDispersionByFiltros(dispersionesFiltrosRequest: DispersionesFiltrosRequest): Observable<any>{
        return this.http.post(this.constantsServices.DISPERSIONES_API, dispersionesFiltrosRequest, {observe: 'response'});
    }

    getAllArchivoCEP(idUsuario: number, idDispersionList: number[]): Observable<any>{
        return this.http.post(this.constantsServices.DISPERSIONES_DESCARGAR_CEP_API + '/' + idUsuario, idDispersionList, { responseType: 'blob', observe: 'response'});
    }

    changeDispersionesTipoPagoTnnToLiquidadas(idUsuario: number, idDispersionList: number[]): Observable<any>{
        return this.http.post(this.constantsServices.DISPERSIONES_TNN_CAMBIAR_ESTATUS_LIQUIDADAS + '/' + idUsuario, idDispersionList, { responseType: 'blob', observe: 'response'});
    }

    //Catalogos de Dispersiones realizadas

    getAllBeneficiarioTipo(): Observable<any>{
        return this.http.get(this.constantsServices.CATALOGO_BENEFICIARIOS_TIPO_API, {observe: 'response'});
    }

    getAllDepartamento(): Observable<any> {
        return this.http.get(this.constantsServices.CATALOGO_DEPARTAMENTOS_API, {observe: 'response'});
    }

    getAllCentroCostos(): Observable<any> {
        return this.http.get(this.constantsServices.CATALOGO_CENTROS_COSTOS_API, {observe: 'response'});
    }

    getAllCentroTrabajo(): Observable<any> {
        return this.http.get(this.constantsServices.CATALOGO_CENTROS_TRABAJO_API, {observe: 'response'});
    }

    public insertDepartamento(nombre: string): Observable<any> {
        return this.http.post(this.constantsServices.CATALOGO_DEPARTAMENTOS_API + '/insertar/' + nombre, null, {observe: 'response'});
    }

    public deleteDepartamentoById(idDepartamento: number): Observable<any> {
        return this.http.delete(this.constantsServices.CATALOGO_DEPARTAMENTOS_API + '/eliminar/' + idDepartamento, {observe: 'response'});
    }

    public insertCentroCostos(nombre: string): Observable<any> {
        return this.http.post(this.constantsServices.CATALOGO_CENTROS_COSTOS_API + '/insertar/' + nombre, null, {observe: 'response'});
    }

    public deleteCentroCostosById(idCentroCostos: number): Observable<any> {
        return this.http.delete(this.constantsServices.CATALOGO_CENTROS_COSTOS_API + '/eliminar/' + idCentroCostos, {observe: 'response'});
    }

    public insertCentroTrabajo(nombre: string): Observable<any> {
        return this.http.post(this.constantsServices.CATALOGO_CENTROS_TRABAJO_API + '/insertar/' + nombre, null, {observe: 'response'});
    }

    public deleteCentroTrabajoById(idCentroTrabajo: number): Observable<any> {
        return this.http.delete(this.constantsServices.CATALOGO_CENTROS_TRABAJO_API + '/eliminar/' + idCentroTrabajo, {observe: 'response'});
    }

    //Funcion de Operaciones

    getLayoutsOperaciones(fechaInicio: string, fechaFin: string): Observable<any>{
        return this.http.get(this.constantsServices.DISPERSIONES_LAYOUTS_OPERACIONES_API + '/' + fechaInicio + '/' + fechaFin, {observe: 'response'});
    }

    //Funciones de la Pantalla Principal

    getCantidadDispersionesByDiaAndEstado(): Observable<any> {
        return this.http.get(this.constantsServices.DISPERSIONES_PANTALLA_PRINCIPAL_API + '/dia', {observe: 'response'});
    }

    getCantidadDispersionesByDiaAndEstadoInSemana(): Observable<any> {
        return this.http.get(this.constantsServices.DISPERSIONES_PANTALLA_PRINCIPAL_API + '/semana-dia', {observe: 'response'});
    }

    getRecibosDispersiones(datos: DatosBusqueda): Observable<any>{
        return this.http.post(this.constantsServices.DESCARGA_RECIBOS_DISPERSION_ZIP, datos);
    }

    getReprocesoDispersion(reproceso: Reproceso): Observable<any>{
        return this.http.post(this.constantsServices.REPROCESO_DISPERSION, reproceso, {observe: 'response'});
    }


    getTiempoRepeticionConsulta(): Observable<any> {
        return this.http.get(this.constantsServices.DISPERSIONES_API + '/tiempoConsulta', {observe: 'response'});
    }

    public enviarComision(datos: EnvioComision): Observable<any> {
        console.log(this.constantsServices.ENVIO_COMISION);
        return this.http.post(this.constantsServices.ENVIO_COMISION, datos, {observe: 'response'});
    }

    public consultarSaldo(): Observable<any> {
        return this.http.get(this.constantsServices.SALDO_CUENTAS, {observe: 'response'});
    }

    public subirArchivoDispersiones(datos: any): Observable<any> {
        return this.http.post(this.constantsServices.CARGA_MULTIPLE, datos, {observe: 'response'});
    }
}