import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Correo } from 'src/app/models/correo';
import { PasswordValidar } from 'src/app/models/password-validar';
import { CommonMessagesService } from 'src/app/services/common-messages.service';
import { LoginService } from 'src/app/services/login.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

const LINK_RECUPERAR_PWD = environment.LINK_RECUPERAR_PWD;

@Component({
  selector: 'app-recuperar-contrasenia',
  templateUrl: './recuperar-contrasenia.component.html'
})
export class RecuperarContraseniaComponent implements OnInit {

  public passwordValidar: PasswordValidar = new PasswordValidar();
  public correo: Correo = new Correo();
  msj: string;
  msjToast: string;

  constructor(private loginService: LoginService,
    private router: Router,
    private commonMsg: CommonMessagesService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.spinner.show();   //Iniciar spinner
    console.log('Datos a enviar : ' + JSON.stringify(this.passwordValidar));
    this.loginService.validarUsuario(this.passwordValidar).subscribe(
      data => {
        console.log(data);
        if (data.body.code === 1 && data.body.msg === "OK") {
          this.correo.correo = this.passwordValidar.email;
          this.correo.asunto = 'Restablece la contraseña de tu cuenta para Seguimiento';
          this.correo.contenido = '<p>Para cambiar la contraseña de tu cuenta debes dar click en la siguiente liga:</p><p style="text-align:center">' + LINK_RECUPERAR_PWD + data.body.usuario.idUsuario +'</p>';
          this.loginService.enviarCorreo(this.correo).subscribe(
            data => {
              if (data.status === 200) {
                console.log('Email OK.');
                this.spinner.hide();   //Ocultar Spinner
                this.msjToast = 'Se envío correo a ' + this.passwordValidar.email + ' verifiquelo.';
                this.commonMsg.crearMSgToast('info', 'Envío de email, correcto.', this.msjToast);
              }
            },
            err => {
              console.log('Error en servicio Email');
              this.spinner.hide();   //Ocultar Spinner
              this.msjToast = 'Error en el envío de email';
              this.commonMsg.crearMSgToast('error', 'Falló envío de email.', this.msjToast);
              this.router.navigate(['./', 'login']);
              this.limpiarCampos(this.passwordValidar);
            }
          );
        } else if (data.body.code === 0 && data.body.msg === "NOT_FOUND") {
          this.spinner.hide();   //Ocultar Spinner
          this.msjToast = 'No se encontró, información disponible.';
          this.commonMsg.crearMSgToast('error', 'Verifique, sus datos.', this.msjToast);
        }
      }, error => {
        this.spinner.hide();   //Ocultar Spinner
        this.msjToast = 'Servicio no disponible.';
        this.commonMsg.crearMSgToast('error', 'Falló', this.msjToast);
      });
  }

  reloadPage(): void {
    window.location.reload();
  }

  limpiarCampos(passwordObj: PasswordValidar): void {
    passwordObj.email = "";
    passwordObj.usuario = "";
  }

  ventanaAlerta(icono: any, titulo: string, texto: string): void {
    Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      footer: '<a href="/login">' + titulo + '</a>'
    });
  }

}
