<div class="wrapper d-flex">
    <div class="page-wrapper">
        <main>
            <div class="row title-wraper">
                <div class="col-12 title-page-fd">
                    <h2>Envió comisión</h2>
                </div>
            </div>
            <br>

            <ngx-spinner bdColor = "rgba(5,0,248,0.8)" size = "default" color = "#fff" type = "ball-spin" fullScreen="true">
                <p style="color: black"> Cargando... </p>
            </ngx-spinner>

            <div class="row content-wrapper-inner container-lavels-fd">
                <div class="col-1"></div>
                <div class="col-10 lavel-fd">
                    <form class="form" [formGroup]="formDispersion">
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <label>Clave empleado:</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="claveEmpleado" name="claveEmpleadoi" 
                                          class="form-control" formControlName="claveEmpleado">
                                    </div>
                                </div>
                            </div>
                            <div class="col-6"></div>
                        </div>

                        <br>

                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <label>Fecha colocación</label>
                                    </div>
                                    <div class="col-6 form-group mb-1">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="DD-MM-AAAA" size="9" name="fechaColocacion"
                                                formControlName="fechaColocacion" aria-describedby="inputGroupPrepend" readonly
                                                ngbDatepicker #di="ngbDatepicker">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroupPrepend" (click)="di.toggle();">
                                                    <a id="edit">
                                                        <i class="fa fa-calendar-o edit-register"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <label>Fecha pago</label>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="Hasta" size="9" name="fechapago"
                                                formControlName="fechaPago" aria-describedby="inputGroupPrepend" readonly
                                                ngbDatepicker
                                                #df="ngbDatepicker">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroupPrepend" (click)="df.toggle();">
                                                    <a id="edit">
                                                        <i class="fa fa-calendar-o edit-register"></i>
                                                    </a>
                                                </span>
                                            </div>
                                            <!--<div class="invalid-feedback">
                                                <p
                                                    *ngIf="formFiltrosDispersiones.get('fechaHasta').value === null && formFiltrosDispersiones.get('fechaDesde').value != null">
                                                    Fecha no puede estar vacia
                                                </p>
                                                <p *ngIf="formFiltrosDispersiones.controls['fechaHasta'].hasError('ngbDate')">
                                                    La fecha es menor a la otra
                                                </p>
                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br>

                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <label>Monto colocación</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="montoColocacion" name="montoColocacion"
                                          class="form-control" formControlName="montoColocacion">
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <label>Monto comisión</label>
                                    </div>
                                    <div class="col-6">
                                        <input type="text" id="montoComision" name="montoComision"
                                          class="form-control" formControlName="montoComision">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br>

                        <div class="row">
                            <div class="col-9"></div>
                            <div class="col-3">
                                <button type="button" [disabled]="bloquear" (click)="enviarDispersion()" class="btn btn-nom-content">Dispersar</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-1"></div>
            </div>

            <!--bloque para carga multiple-->
            <div class="row title-wraper">
                <div class="col-12 title-page-fd">
                    <h2>Envió por Layout</h2>
                </div>
            </div>
            <br>

            <div class="row content-wrapper-inner container-lavels-fd">
                <div class="col-1"></div>
                <div class="col-3">
                    <div class="row">
                        <div class="col-12">
                            <h4>PASO 1.</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p>Descargar layout para carga</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <a id="descargarLayout" href="{{urlLayout}}" title="Descargar Layout">Descargar</a>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col-12">
                            <h4>PASO 2.</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p>Llenar el layout con las dispersiones a mandar.</p>
                        </div>
                    </div>
                </div>
                <div class="col-3 lavel-fd">
                    <div class="row">
                        <div class="col-12">
                            <h4>PASO 3.</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p>Subir el archivo y procesar.</p>
                        </div>
                    </div>
                    <form class="form" [formGroup]="formMultiple">
                        <div class="row">
                            <div class="col-12">
                                <input id="archivoDispersion" formControlName="archivo" type="file" class="form-control"
                                  required (change)="cargarArchivoDispersiones($event)" 
                                  [ngClass]="formMultiple.controls['archivo'].hasError('required') ? 'is-invalid' : ''">
                                  <div class="invalid-feedback">
                                    Archivo requerido.
                                  </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <br><br>
                                <button type="submit" (click)="subirArchivodispersiones()"
                                  class="btn btn-nom-content">Subir Archivo</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-1"></div>
            </div>
        </main>
    </div>
</div>
<br><br><br><br><br><br>
