import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { error } from 'console';
import { NgxSpinnerService } from 'ngx-spinner';
import { Archivo } from 'src/app/models/archivo';
import { BeneficiariosService } from 'src/app/services/beneficiarios.service';
import { CommonMessagesService } from 'src/app/services/common-messages.service';

@Component({
  selector: 'app-carga-empleados',
  templateUrl: './carga-empleados.component.html'
})
export class CargaEmpleadosComponent implements OnInit {

  formArchivoBeneficiarios: FormGroup;
  formDataArchivoDB: FormData = new FormData();

  formArchivoBeneficiariosEnviar: boolean = false;

  layoutsOperaciones: Archivo[] = new Array();

  constructor(private commonMsg: CommonMessagesService,
    private beneficiariosService: BeneficiariosService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService) {
    this.formArchivoBeneficiarios = this.crearFormularioArchivoBeneficiarios();
  }

  ngOnInit(): void {
    this.spinner.hide();
  }

  crearFormularioArchivoBeneficiarios(): FormGroup {
    return this.formBuilder.group({
      archivo: [null, Validators.required]
    });
  }

  async cargarArchivoDatosBeneficiarios(event) {
    const file = event.target.files[0];
    if (file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type != 'application/vnd.ms-excel' ) {
      this.commonMsg.crearMSgToast('error', 'No es un archivo *.xlsx o *.xls válido.', 'Archivo no válido');
      this.formArchivoBeneficiarios.controls['archivo'].setValue(null);
    } else if (file.size > 5000000) {
      this.commonMsg.crearMSgToast('error', 'El archivo debe ser como máximo de 5Mb', 'Archivo no válido');
      this.formArchivoBeneficiarios.controls['archivo'].setValue(null);
    } else {
      if(this.formDataArchivoDB.get('archivoDatosBeneficiarios') != null) {
        this.formDataArchivoDB.delete('archivoDatosBeneficiarios');
      }
      this.formDataArchivoDB.append('archivoDatosBeneficiarios', file);
    }
  }

  subirArchivoDatosBeneficiarios() {
    this.formArchivoBeneficiariosEnviar = true;
    
    if (this.formArchivoBeneficiarios.valid) {
      this.spinner.show();
      this.beneficiariosService.subirArchivoDatosBeneficiarios(this.formDataArchivoDB).subscribe(
        response => {
          let respuesta: boolean = response.body;
          if(respuesta) {
            this.commonMsg.crearMSgToast('info', 'Exitoso', 'Se subio el archivo correctamente, inicia la carga de la plantilla');
            this.beneficiariosService.actualizarDatosBeneficiarios().subscribe(
              response => { }, error => {
                console.log(error);
              }
            );
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.commonMsg.crearMSgToast('error', 'Falló', 'El archivo no subio correctamente.');
          }
        }, error => {
          console.log(error);
          this.spinner.hide();
          this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
        }
      );
    }
  }

  consultarArchivoDatosBeneficiarios(){
    console.log("descargando archivo de empleados...");
    this.spinner.show();
    this.beneficiariosService.getArchivoEmpleados().subscribe(response => {
      this.spinner.hide();
      let data = response;
      let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'DatosEmpelados.xlsx';
      downloadLink.click();
    }, error => {
      console.log(error);
      this.spinner.hide();
      console.log("Error al descargar archivo de empleados");
    });
  }

  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

}
