<div class="wrapper d-flex">
    <div class="page-wrapper">
        <main>
            <div class="row title-wraper">
                <div class="col-12 col-sm-6 col-md-6 title-page-fd">
                    <h2>Carga empleados</h2>
                </div>
            </div>
            <!--<ngx-spinner bdColor="#f0f2f7" size="default" color="black" type="ball-spin" [fullScreen]="false">
                <p style="color: black"> Cargando... </p>
            </ngx-spinner>-->
            <ngx-spinner bdColor = "rgba(5,0,248,0.8)" size = "default" color = "#fff" type = "ball-spin" fullScreen="true">
                <p style="color: black"> Cargando... </p>
            </ngx-spinner>

            <div class="row">
                
                <div class="col-12 tabla-operaciones">
                    <form class="needs-validation" novalidate [formGroup]="formArchivoBeneficiarios">
                        <div class="row content-wrapper-inner container-lavels-fd">
                            <div class="col-10">
                                <div class="row">
                                    <div class="col-4 col-sm-4">
                                        <label>Cargar Archivo: </label>
                                    </div>
                                    <div class="col-8 col-sm-8">
                                        <input id="archivoBeneficiarios" formControlName="archivo" type="file" class="form-control" required (change)="cargarArchivoDatosBeneficiarios($event)"
                                        [ngClass]="formArchivoBeneficiariosEnviar ? formArchivoBeneficiarios.controls['archivo'].hasError('required') ? 'is-invalid' : '' : ''">
                                        <div class="invalid-feedback">
                                            Archivo requerido.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="row">
                                    <div class="col-12">
                                        <button type="submit" (click)="subirArchivoDatosBeneficiarios()"
                                            class="btn btn-nom-content">Subir Archivo</button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <button type="submit" (click)="consultarArchivoDatosBeneficiarios()"
                                            class="btn btn-nom-content">Consultar Empleados</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

            <div class="row">
                <div class="col-12 tabla-operaciones">
                    <div class="row">
                        <h6>
                            Archivos cargados
                        </h6>
                    </div>

                    <div class="row">
                        <ul>
                            <li *ngFor="let dato of archivos">
                                <a href="#" (click)="abrirModalInformacion(dato, $event)">{{dato}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </main>
    </div>
</div>
