import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'dispersion-cane';

  constructor(private activatedRoute: ActivatedRoute, private router: Router){

  }

  ngOnInit(): void {
    const path = this.activatedRoute.snapshot.queryParams['path'];
    const navigateTo = '/' + path;

    if(path){
      this.router.navigate([navigateTo]);
    }
    //throw new Error('Method not implemented.');
  }
  
}
