<div class="wrapper d-flex">
    <div class="sidemenu">
        <ul class="navbar-nav">
            <li class="nav-item burger">
                <a class="sideMenuToggler"><i class="icon-menu"></i>
                    <span class="text"></span>
                </a>
            </li>
            <li class="nav-item" *ngIf="idPerfil === idPerfilOperador || idPerfil === idPerfilGerente">
                <a routerLink="/dashboard" class="nav-link nav-action"><i class="ti-home"></i><span
                        class="text">Principal</span></a>
            </li>
            <li class="nav-item">
                <a routerLink="dispersiones-realizadas" class="nav-link nav-action"><i class="ti-agenda"></i><span
                    class="text">Dispersiones realizadas</span></a>
            </li>
            <li class="nav-item">
                <a routerLink="envio-comision" class="nav-link nav-action"><i class="ti-agenda"></i><span
                    class="text">Envió comisión</span></a>
            </li>
            <li class="nav-item" *ngIf="idPerfil === idPerfilOperador">
                <a routerLink="operaciones" class="nav-link nav-action"><i class="ti-agenda"></i><span
                    class="text">Operaciones</span></a>
            </li>
            <li class="nav-item" *ngIf="idPerfil === idPerfilOperador">
                <a routerLink="carga-empleados" class="nav-link nav-action"><i class="ti-agenda"></i><span
                    class="text">Carga empleados</span></a>
            </li>
            <li class="nav-item" *ngIf="idPerfil === idPerfilOperador">
                <a routerLink="saldo-cuentas" class="nav-link nav-action"><i class="ti-agenda"></i><span
                    class="text">Saldos</span></a>
            </li>
            <!-- <li class="nav-item" *ngIf="idPerfil === idPerfilOperador">
                <a routerLink="log-carga-empleados" class="nav-link nav-action"><i class="ti-agenda"></i><span
                    class="text">Log empleados</span></a>
            </li>
            <li class="nav-item" *ngIf="idPerfil === idPerfilOperador">
                <a routerLink="administracion-catalogos" class="nav-link nav-action"><i class="ti-agenda"></i><span
                    class="text">Administación de catálogos</span></a> 
            </li> -->
        </ul>
    </div>
</div>  
