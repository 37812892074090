import { Injectable } from "@angular/core";
import { SortEvent } from "primeng/api";

@Injectable()
export class SortService {
    ordenarExistenFechas(event: SortEvent) {
        const regExpFecha = new RegExp(/^(?:(?:31(-)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(-)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(-)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(-)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/),
            regExpFechaHora = new RegExp(/^([0-2][0-9]|3[0-1])(\/|-)(0[1-9]|1[0-2])\2(\d{4})(\s)([0-1][0-9]|2[0-3])(:)([0-5][0-9])(:)([0-5][0-9])$/);
        event.data.sort((data1, data2) => {
            let value1 = data1[event.field];
            let value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                if (event.field === "monto") {

                    const monto1 = parseFloat(value1.substring(1,value1.length));

                    const monto2 = parseFloat(value2.substring(1,value1.length));

                    result = monto1 < monto2 ? -1 : monto1 > monto2 ? 1 : 0;

                } else if (regExpFecha.test(value1.substring(0,19)) && regExpFecha.test(value2.substring(0,19))) {
                    const diaValue1 = parseInt(value1.substring(0, 2)), mesValue1 = parseInt(value1.substring(3, 5)), anioValue1 = parseInt(value1.substring(6, 10)),
                        diaValue2 = parseInt(value2.substring(0, 2)), mesValue2 = parseInt(value2.substring(3, 5)), anioValue2 = parseInt(value2.substring(6, 10));
                    if (anioValue1 < anioValue2)
                        result = -1;
                    else if (anioValue1 > anioValue2)
                        result = 1;
                    else {
                        if (mesValue1 < mesValue2)
                            result = -1;
                        else if (mesValue1 > mesValue2)
                            result = 1;
                        else {
                            if (diaValue1 < diaValue2)
                                result = -1;
                            else if (diaValue1 > diaValue2)
                                result = 1;
                            else
                                result = 0;
                        }
                    }
                } else if (regExpFechaHora.test(value1) && regExpFechaHora.test(value2)) {
                    const segundosValue1 = parseInt(value1.substring(17, 19)), minutosValue1 = parseInt(value1.substring(14, 16)), horasValue1 = parseInt(value1.substring(11, 13)),
                        diaValue1 = parseInt(value1.substring(0, 2)), mesValue1 = parseInt(value1.substring(3, 5)), anioValue1 = parseInt(value1.substring(6, 10)),
                        segundosValue2 = parseInt(value2.substring(17, 19)), minutosValue2 = parseInt(value2.substring(14, 16)), horasValue2 = parseInt(value2.substring(11, 13)),
                        diaValue2 = parseInt(value2.substring(0, 2)), mesValue2 = parseInt(value2.substring(3, 5)), anioValue2 = parseInt(value2.substring(6, 10));
                    if (anioValue1 < anioValue2)
                        result = -1;
                    else if (anioValue1 > anioValue2)
                        result = 1;
                    else {
                        if (mesValue1 < mesValue2)
                            result = -1;
                        else if (mesValue1 > mesValue2)
                            result = 1;
                        else {
                            if (diaValue1 < diaValue2)
                                result = -1;
                            else if (diaValue1 > diaValue2)
                                result = 1;
                            else {
                                if (horasValue1 < horasValue2)
                                    result = -1;
                                else if (horasValue1 > horasValue2)
                                    result = 1;
                                else {
                                    if (minutosValue1 < minutosValue2)
                                        result = -1;
                                    else if (minutosValue1 > minutosValue2)
                                        result = 1;
                                    else {
                                        if (segundosValue1 < segundosValue2)
                                            result = -1;
                                        else if (segundosValue1 > segundosValue2)
                                            result = 1;
                                        else
                                            result = 0;
                                    }
                                }
                            }
                        }
                    }
                }
                else
                    result = value1.localeCompare(value2);
            }
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });
    }

}