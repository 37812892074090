import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(private localStorageService: LocalStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.localStorageService.get('token');
    const idUsuario = this.localStorageService.get("idUsuario");

    let request = req;

    if(token && idUsuario){
      request = req.clone({
        setHeaders: {
          authorization: `${token}`,
          idUsuario: `${idUsuario.toString()}`
        }
      });
    }

    return next.handle(request);
    
  }
}