import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DispersionesService } from 'src/app/services/dispersiones.service';
import { CustomValidators } from 'src/app/validations/custom-validators';
import { MensajeModalComponent } from '../utils/mensaje-modal/mensaje-modal.component';

@Component({
  selector: 'app-centros-trabajo-form-modal',
  templateUrl: './centros-trabajo-form-modal.component.html'
})
export class CentrosTrabajoFormModalComponent implements OnInit {

  public formCentroTrabajo: FormGroup;

  public modalOptions: NgbModalOptions;

  constructor(private spinner: NgxSpinnerService,
    private dispersionesService: DispersionesService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal) {
    this.formCentroTrabajo = this.createFormCentroTrabajo();
  }

  ngOnInit(): void {
    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      keyboard: false,
      size: 'sm'
    }
  }

  private createFormCentroTrabajo(): FormGroup {
    return this.formBuilder.group({
      nombre: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(100),
        CustomValidators.patternValidator(/^(?![0-9\s]+$)[\s0-9a-zA-ZÑñÁáÉéÍíÓóÚú_*()&+-]*$/, { isInvalidNombre: true })
      ])]
    });
  }

  public guardarCentroTrabajo() {
    this.spinner.show();
    if (this.formCentroTrabajo.valid) {
      const nombre = this.formCentroTrabajo.get('nombre').value;
      this.dispersionesService.insertCentroTrabajo(nombre).subscribe(
        response => {
          const resp = response.body;
          if (resp) {
            this.mostrarMensajeModal('Guardado', 'El registro fue cargado correctamente.', true);
          }
        }, error => {
          console.log(error);
          this.mostrarMensajeModal('Error', error.error.errorMessage);
        }
      );
    } else {
      let mensajeModal = '';
      if (this.formCentroTrabajo.get('nombre').hasError('required')) {
        mensajeModal = 'El nombre es obligatorio.';
      }
      if (this.formCentroTrabajo.get('nombre').hasError('maxLength')) {
        mensajeModal = 'El nombre es de maximo 40 caracteres.';
      }
      if (this.formCentroTrabajo.get('nombre').hasError('isInvalidNombre')) {
        mensajeModal = 'El nombre no puede ser númerico.';
      }
      this.mostrarMensajeModal('Error', mensajeModal);
    }
  }

  public cancelar() {
    this.activeModal.close();
  }

  public mostrarMensajeModal(tituloModal: string, mensajeModal: string, cerrarModal?: boolean) {
    const modalMensaje = this.modalService.open(MensajeModalComponent, this.modalOptions);
    modalMensaje.componentInstance.tituloModal = tituloModal;
    modalMensaje.componentInstance.mensajeModal = mensajeModal;
    modalMensaje.result.then(() => {
      if (cerrarModal) {
        this.activeModal.close();
      }
    });
  }

}
