<div class="wrapper d-flex">
  <div class="page-wrapper" style="min-height: 900px;">
    <main>
      <div class="row title-wraper">
        <div class="col-12 title-page-fd">
          <h2>Dispersiones Realizadas</h2>
        </div>
      </div>
      <div class="container-fluid mt-3">
        <div class="row content-wrapper-inner">
          <div class="col-12 col-md-5">
            <div class="card bg-gradient-default shadow" *ngIf="idPerfil === idPerfilOperador || idPerfil === idPerfilGerente">
              <div class="card-header bg-transparent">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5>Dispersiones del día</h5>
                  </div>
                  <div class="col-6">
                    <p>{{fechaActualFormato}}</p>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <!-- Chart -->
                <div *ngIf="mostrarGraficaDia; else mostrarMensajeGraficoDia" class="chart">
                  <!-- Chart wrapper -->
                  <canvas id="graficaDia" class="chart-canvas"></canvas>
                  <p-card>
                    <div class="row">
                      <div class="col-7">
                        <div class="row">
                          <div class="col-3"><strong>{{graficoDispersionesDia.dispersionesLiquidadas}}</strong></div>
                          <div class="col-9">
                            <!-- Remplazar todos los cuadritos de icono por unos de colores-->
                            <i class="fa fa-square liquidadas" aria-hidden="true" style="padding-right: 10px;"></i>
                            <label for="liquidadas">Liquidadas</label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3"><strong>{{graficoDispersionesDia.dispersionesProcesando}}</strong></div>
                          <div class="col-9">
                            <i class="fa fa-square procesando" aria-hidden="true" style="padding-right: 10px;"></i>
                            <label for="procesando">Procesando</label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3"><strong>{{graficoDispersionesDia.dispersionesRechazadas}}</strong></div>
                          <div class="col-9">
                            <i class="fa fa-square rechazadas" aria-hidden="true" style="padding-right: 10px;"></i>
                            <label for="rechazadas">Rechazadas</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-1"></div>
                      <div class="col-4">
                        <div class="row">
                          <div class="col-12 text-right">
                            <label for="total">Total:
                              <strong>{{graficoDispersionesDia.dispersionesTotales}}</strong></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-card>
                </div>
                <ng-template #mostrarMensajeGraficoDia>
                  <div>
                    <p>{{mensajeGraficaDia}}</p>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-7">
            <div class="card shadow" *ngIf="idPerfil === idPerfilOperador">
              <div class="card-header bg-transparent">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5>Dispersiones de la semana</h5>
                  </div>
                  <div class="col-4">
                    <p *ngIf="mostrarRangoFecha">{{fechaPrimerDiaSemanaFormato}} al {{fechaUltimoDiaSemanaFormato}}</p>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <!-- Chart -->
                <div *ngIf="mostrarGraficaDiaSemana; else mostrarMensajeGraficoDiaSemana" class="chart">
                  <canvas id="graficaDiaSemana" class="chart-canvas"></canvas>
                  <p-card>
                    <div class="row">
                      <div class="col-sm-5">
                      </div>
                      <div class="col-sm-1">
                        <p><strong>Lun</strong></p>
                      </div>
                      <div class="col-sm-1">
                        <p><strong>Mar</strong></p>
                      </div>
                      <div class="col-sm-1">
                        <p><strong>Mie</strong></p>
                      </div>
                      <div class="col-sm-1">
                        <p><strong>Jue</strong></p>
                      </div>
                      <div class="col-sm-1">
                        <p><strong>Vie</strong></p>
                      </div>
                      <div class="col-sm-2">
                        <p><strong>TOTAL</strong></p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5">
                        <i class="fa fa-square liquidadas" aria-hidden="true" style="padding-right: 10px;"></i>
                        <label for="liquidadas">Liquidadas</label>
                      </div>
                      <ng-template ngFor let-graficoDispersionesDia [ngForOf]="graficoDispersionesDiaSemana">
                        <div class="col-sm-1">
                          <p>{{graficoDispersionesDia.dispersionesLiquidadas}}</p>
                        </div>
                      </ng-template>
                      <div class="col-sm-2">
                        <p><strong>{{totalDispersionesSemanaLiquidadas}}</strong></p>
                      </div>
                    </div>
                   
                    <div class="row">
                      <div class="col-sm-5">
                        <i class="fa fa-square procesando" aria-hidden="true" style="padding-right: 10px;"></i>
                        <label for="rechazadas">Procesando</label>
                      </div>
                      <ng-template ngFor let-graficoDispersionesDia [ngForOf]="graficoDispersionesDiaSemana">
                        <div class="col-sm-1">
                          <p>{{graficoDispersionesDia.dispersionesProcesando}}</p>
                        </div>
                      </ng-template>
                      <div class="col-sm-2">
                        <p><strong>{{totalDispersionesSemanaProcesando}}</strong></p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <i class="fa fa-square rechazadas" aria-hidden="true" style="padding-right: 10px;"></i>
                        <label for="rechazadas">Rechazadas</label>
                      </div>
                      <ng-template ngFor let-graficoDispersionesDia [ngForOf]="graficoDispersionesDiaSemana">
                        <div class="col-sm-1">
                          <p>{{graficoDispersionesDia.dispersionesRechazadas}}</p>
                        </div>
                      </ng-template>
                      <div class="col-sm-2">
                        <p><strong>{{totalDispersionesSemanaRechazadas}}</strong></p>
                      </div>
                    </div>

                  </p-card>
                </div>
                <ng-template #mostrarMensajeGraficoDiaSemana>
                  <div>
                    <p>{{mensajeGraficaDia}}</p>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>