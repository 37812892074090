<div class="wrapper d-flex">
    <div class="page-wrapper">
        <main>
            <div class="row title-wraper">
                <div class="col-12 title-page-fd">
                    <h2>Administración de catálogos</h2>
                </div>
            </div>
            <div class="row content-wrapper-inner mt-2">
                <div class="col-4"><h4>Departamentos</h4></div>
                <div class="col-4"><h4>Centros de costos</h4></div>
                <div class="col-4"><h4>Centros de trabajo</h4></div>
            </div>
            <div class="row content-wrapper-inner mt-2">
                <div class="col-4">
                    <p-table #tableDepartamentos dataKey="id" [value]="departamentos" [rows]="10"
                        [rowsPerPageOptions]="[10,20,30]" styleClass="p-datatable-sm" [paginator]="true"
                        [alwaysShowPaginator]="mostrarPaginadorTablaDepartamentos" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Resultado: {first} - {last} de {totalRecords}"
                        [globalFilterFields]="['nombre']">
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="2" class="text-center">{{mensajeTablaDepartamentos}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr class="head-top">
                                <th pSortableColumn="nombre">Nombre <p-sortIcon field="nombre">
                                    </p-sortIcon>
                                </th>
                                <th>
                                    Acción
                                </th>
                            </tr>
                            <tr class="head-bottom">
                                <th>
                                    <p-columnFilter type="text" field="nombre"></p-columnFilter>
                                </th>
                                <th>
                                    <!--Vacio para acciones-->
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-departamento>
                            <tr class="ng-star-inserted">
                                <td>{{departamento.nombre}}</td>
                                <td style="text-align: center;">
                                    <a id="eliminarDepartamento" style="cursor: pointer">
                                        <i class="text-danger ti-trash" (click)="eliminarDepartamento(departamento.idCatDepartamento)" data-toggle="tooltip" data-placement="top" title="Eliminar"></i>
                                    </a>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <button style="margin-top: 15px;" type="submit" class="btn btn-nom-content" (click)="abrirFormModal(1)">Agregar</button>
                </div>
                <div class="col-4">
                    <p-table #tableCentrosCostos dataKey="id" [value]="centrosCostos" [rows]="10"
                        [rowsPerPageOptions]="[10,20,30]" styleClass="p-datatable-sm" [paginator]="true"
                        [alwaysShowPaginator]="mostrarPaginadorTablaCentrosCostos" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Resultado: {first} - {last} de {totalRecords}"
                        [globalFilterFields]="['nombre']">
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="2" class="text-center">{{mensajeTablaCentrosCostos}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr class="head-top">
                                <th pSortableColumn="nombre">Nombre <p-sortIcon field="nombre">
                                    </p-sortIcon>
                                </th>
                                <th>
                                    Acción
                                </th>
                            </tr>
                            <tr class="head-bottom">
                                <th>
                                    <p-columnFilter type="text" field="nombre"></p-columnFilter>
                                </th>
                                <th>
                                    <!--Vacio para acciones-->
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-centroCostos>
                            <tr class="ng-star-inserted">
                                <td>{{centroCostos.nombre}}</td>
                                <td style="text-align: center;">
                                    <a id="eliminarCentroCostos" style="cursor: pointer">
                                        <i class="text-danger ti-trash" (click)="eliminarCentroCostos(centroCostos.idCatCentroCosto)" data-toggle="tooltip" data-placement="top" title="Eliminar"></i>
                                    </a>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <button style="margin-top: 15px;" type="submit" class="btn btn-nom-content" (click)="abrirFormModal(2)">Agregar</button>
                </div>
                <div class="col-4">
                    <p-table #tableCentrosTrabajo dataKey="id" [value]="centrosTrabajo" [rows]="10"
                        [rowsPerPageOptions]="[10,20,30]" styleClass="p-datatable-sm" [paginator]="true"
                        [alwaysShowPaginator]="mostrarPaginadorTablaCentrosTrabajo" [showCurrentPageReport]="true"
                        currentPageReportTemplate="Resultado: {first} - {last} de {totalRecords}"
                        [globalFilterFields]="['nombre']">
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="2" class="text-center">{{mensajeTablaCentrosTrabajo}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr class="head-top">
                                <th pSortableColumn="nombre">Nombre <p-sortIcon field="nombre">
                                    </p-sortIcon>
                                </th>
                                <th>
                                    Acción
                                </th>
                            </tr>
                            <tr class="head-bottom">
                                <th>
                                    <p-columnFilter type="text" field="nombre"></p-columnFilter>
                                </th>
                                <th>
                                    <!--Vacio para acciones-->
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-centroTrabajo>
                            <tr class="ng-star-inserted">
                                <td>{{centroTrabajo.nombre}}</td>
                                <td style="text-align: center;">
                                    <a id="eliminarCentroTrabajo" style="cursor: pointer">
                                        <i class="text-danger ti-trash" (click)="eliminarCentroTrabajo(centroTrabajo.idCatCentroTrabajo)" data-toggle="tooltip" data-placement="top" title="Eliminar"></i>
                                    </a>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <button style="margin-top: 15px;" type="submit" class="btn btn-nom-content" (click)="abrirFormModal(3)">Agregar</button>
                </div>
            </div>
        </main>
    </div>
</div>