<div class="modal-header">
    <h5 class="modal-title">¿Estas comisiones ya fueron pagadas?</h5>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <p-card>
                <ng-template ngFor let-dispersion [ngForOf]="dispersionesTnn" let-i="index">
                    <div class="row">
                        <div class="col-9">
                            <label>{{i + 1}} .- {{dispersion.nombreEmpleado}}</label>
                        </div>
                        <div class="col-3">
                            <label>{{dispersion.monto}}</label>
                        </div>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </div>
    <div class="row">
        <div class="col-6">Total registros: {{totalRegistros}}</div>
        <div class="col-3">Total dispersión: </div>
        <div class="col-3">{{totalDispersion}}</div>
    </div>
</div>
<div class="col-12 modal-footer">
    <button type="button" class="btn btn-nom-success" (click)="cerrar(true)">Pagado</button>
    <button type="button" class="btn btn-nom-danger" data-dismiss="modal" data-backdrop="false"
        (click)="cerrar(false)">Cancelar</button>
</div>