import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EnvioComision } from 'src/app/models/envio-comision';
import { CommonMessagesService } from 'src/app/services/common-messages.service';
import { DispersionesService } from 'src/app/services/dispersiones.service';
import { FechaService } from 'src/app/util/fecha-service';
import { SortService } from 'src/app/util/sort.service';
import { delay, map,  tap } from "rxjs/operators";
import { NgxSpinnerService } from 'ngx-spinner';
import { ConstantsService } from 'src/app/util/constants.services';
import { MensajeModalComponent } from '../utils/mensaje-modal/mensaje-modal.component';

@Component({
  selector: 'app-envio-comision',
  templateUrl: './envio-comision.component.html'
})
export class EnvioComisionComponent implements OnInit {

  formDispersion: FormGroup;
  hoy: Date;
  modalOptions: NgbModalOptions;
  datosEnvio: EnvioComision = new EnvioComision();
  bloquear: boolean = false;

  formMultiple: FormGroup;
  urlLayout: string;
  formEnviar: FormData = new FormData();
  mensajeModal: string;

  constructor(private dispersionesService: DispersionesService,
    private commonMsg: CommonMessagesService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private fechaService: FechaService,
    private sortService: SortService,
    private spinner: NgxSpinnerService,
    private constantService: ConstantsService) { 
      this.modalOptions = {
        backdrop: 'static',
        centered: true
      }
      this.spinner.hide();
    }

  ngOnInit(): void {
    this.createFormDispersion();
    this.createFormMultiple();
    this.urlLayout = this.constantService.ARCHIVO_DISPERSIONES;
  }

  private createFormDispersion() {
    this.formDispersion = this.formBuilder.group({
      claveEmpleado: [null, Validators.required],
      fechaColocacion: [null, Validators.required],
      fechaPago: [null, Validators.required],
      montoColocacion: [null, Validators.required],
      montoComision: [null, Validators.required]
    });
  }

  private createFormMultiple() {
    this.formMultiple = this.formBuilder.group({
      archivo: [null, Validators.required]
    });
  }

  public enviarDispersion(){
    console.log('procediendo a enviar formulario...');

    if(this.formDispersion.valid){
      this.spinner.show();
      this.bloquear = true;
      //valida fechas y valores
      if(this.formDispersion.get('montoComision').value > 0){
        let fecha1 = this.formDispersion.get('fechaColocacion').value;
        let fecha2 = this.formDispersion.get('fechaPago').value;
        let fechaC1: Date = new Date(fecha1.year, fecha1.month - 1 , fecha1.day);
        let fechaC2: Date = new Date(fecha2.year, fecha2.month - 1, fecha2.day);
      
        console.log(fechaC1);
        console.log(fechaC2);
        if(fechaC1 < fechaC2){
          this.datosEnvio.claveEmpleado = this.formDispersion.get('claveEmpleado').value;
          this.datosEnvio.fechaColocacion = this.fechaService.getFecha(this.formDispersion.get('fechaColocacion').value);
          this.datosEnvio.fechaPago = this.fechaService.getFecha(this.formDispersion.get('fechaPago').value);
          this.datosEnvio.montoColocacion = this.formDispersion.get('montoColocacion').value;
          this.datosEnvio.montoComision = this.formDispersion.get('montoComision').value;

          console.log(this.datosEnvio);
          this.dispersionesService.enviarComision(this.datosEnvio).subscribe(response => {
            console.log(response.body);
            let respuesta = response.body;
            if(respuesta.code == 1){
              //envio exitoso
              this.commonMsg.crearMSgToast('info', 'Exitoso', 'La dispersión fue enviada correctamente.');
              this.bloquear = false;
              this.spinner.hide();
              this.limpiarFormularios();
            }
            else if(respuesta.code == 2){
              //empleado no existe
              this.commonMsg.crearMSgToast('error', 'Error', 'Clave empleado no existente');
              this.bloquear = false;
              this.spinner.hide();
            }
            else{
              this.bloquear = false;
              this.spinner.hide();
              this.commonMsg.crearMSgToast('error', 'Error', 'Error al realizar la petición.');
            }
          }, error => {
            console.log(error);
            this.bloquear = false;
            this.spinner.hide();
            this.commonMsg.crearMSgToast('error', 'Error al realizar petición', 'Servicio no disponible.');
          });
        }
        else{
          this.bloquear = false;
          this.spinner.hide();
          this.commonMsg.crearMSgToast('error', 'Informacion incompleta', 'La fecha de colocación debe ser menor a la fecha de pago');
        }
      }
      else{
        this.bloquear = false;
        this.spinner.hide();
        this.commonMsg.crearMSgToast('error', 'Informacion incompleta', 'Monto comisión debe ser mayor a cero');
      }

    }
    else{
      this.spinner.hide();
      this.commonMsg.crearMSgToast('error', 'Informacion incompleta', 'Debe capturar todos los campos');
    }
  }

  async cargarArchivoDispersiones(event){
    const file = event.target.files[0];
    if (file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type != 'application/vnd.ms-excel'){
      this.commonMsg.crearMSgToast('error', 'No es un archivo *.xlsx o *.xls válido.', 'Archivo no válido');
      this.formMultiple.controls['archivo'].setValue(null);
    } else if (file.size > 5000000) {
      this.commonMsg.crearMSgToast('error', 'El archivo debe ser como máximo de 5Mb', 'Archivo no válido');
      this.formMultiple.controls['archivo'].setValue(null);
    } else {
      if(this.formEnviar.get('archivoDatos') != null) {
        this.formEnviar.delete('archivoDatos');
      }
      this.formEnviar.append('archivoDatos', file);
    }
  }

  subirArchivodispersiones(){

    if(this.formMultiple.valid){
      this.spinner.show();
      this.dispersionesService.subirArchivoDispersiones(this.formEnviar).subscribe(
        response => {
          let respuesta = response.body;
          if(respuesta.estatus == true){
            this.spinner.hide();
            this.limpiarFormularios();
            console.log('carga exitosa...');
            this.mensajeModal = 'Se hizo el envio de '+ respuesta.dispersiones + " dispersiones."
            this.mostrarModalMensaje(1);
          }
          else{
            this.spinner.hide();
            this.limpiarFormularios();
            this.mensajeModal = 'Error al cargar archivo de disperiones.';
            this.mostrarModalMensaje(3);
          }
        },
        error => {
          console.log("Error al realizar peticion...");
          this.spinner.hide();
          this.mostrarMensajeServidor(error);
          //this.commonMsg.crearMSgToast('error', 'Fallo', 'Error al cargar archivo');
        });
    }
  }

  limpiarFormularios(){
    this.formDispersion.patchValue({
      claveEmpleado: null,
      fechaColocacion: null,
      fechaPago: null,
      montoColocacion: null,
      montoComision: null
    });

    this.formMultiple.patchValue({
      archivo: null
    });
  }


  private mostrarModalMensaje(numeroIcono: number): void {
    const modalMensaje = this.modalService.open(MensajeModalComponent, this.modalOptions);
    modalMensaje.componentInstance.tituloModal = "Dispersiones enviadas";
    modalMensaje.componentInstance.mensajeModal = this.mensajeModal;
    modalMensaje.componentInstance.numeroIcono = numeroIcono;
    modalMensaje.result.then(() => { });
  }

  private mostrarMensajeServidor(error: any): void {
    console.log(error);
    if (error.error.errorMessage !== undefined) {
      this.mensajeModal = error.error.errorMessage + ' (Estatus: ' + error.error.errorCode + ')';
    } else if (error.error.errorMessageList !== undefined) {
      const errorList: string[] = error.error.errorMessageList;
      this.mensajeModal = errorList.join(', ') + ' (Estatus: ' + error.error.errorCode + ')';
    } else {
      this.mensajeModal = error.message + ' (Estatus: 500)';
    }
    const modalMensaje = this.modalService.open(MensajeModalComponent, this.modalOptions);
    modalMensaje.componentInstance.mensajeModal = this.mensajeModal;
    modalMensaje.componentInstance.numeroIcono = 3;
    modalMensaje.result.then(() => { });
  }
}
