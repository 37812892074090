import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LogCargaEmpleados } from 'src/app/models/log-carga-empleado';
import { BeneficiariosService } from 'src/app/services/beneficiarios.service';
import { CommonMessagesService } from 'src/app/services/common-messages.service';

@Component({
  selector: 'app-log-carga-empleados',
  templateUrl: './log-carga-empleados.component.html'
})
export class LogCargaEmpleadosComponent implements OnInit {

  public logCargaEmpleadosList: LogCargaEmpleados[] = new Array();

  constructor(private beneficiariosService: BeneficiariosService,
    private spinner: NgxSpinnerService,
    private commonMsg: CommonMessagesService) { }

  ngOnInit(): void {
    this.getLastLogCargaEmpleados();
  }

  private getLastLogCargaEmpleados(): void {
    this.spinner.show();
    this.beneficiariosService.getTenLastLogCargaEmpleados().subscribe(
      response => {
        this.logCargaEmpleadosList = response.body;
        console.log(this.logCargaEmpleadosList);
        this.spinner.hide();
      }, error => {
        console.log(error);
        this.spinner.hide();
        this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');

      }
    );
  }

}
