<div class="modal-header">
    <h5 class="modal-title">Reprocesar</h5>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <form name="reprocesoAvanzadoModalForm" id="reprocesoAvanzadoModalForm"
                [formGroup]="reprocesoAvanzadoModalForm">
                <div class="input-group">
                    <div style="padding-left: 1rem;padding-right: 1rem;">
                        <label for="tipoReproceso1">
                            <input id="tipoReproceso1" [name]="CAMPO_TIPO_REPROCESO" class="mb-3" type="radio"
                                [value]="TIPO_REPROCESO_STP_BAJA" [formControlName]="CAMPO_TIPO_REPROCESO"
                                (change)="onChangeTipoReproceso()"><span>¿Enviar por STP apesar de que el empleado este dado de baja?</span>
                        </label>
                    </div>
                    <div style="padding-left: 1rem;padding-right: 1rem;">
                        <label for="tipoReproceso2">
                            <input id="tipoReproceso2" [name]="CAMPO_TIPO_REPROCESO" class="mb-3" type="radio"
                                [value]="TIPO_REPROCESO_OTRO" [formControlName]="CAMPO_TIPO_REPROCESO"
                                (change)="onChangeTipoReproceso()"><span>¿Se ocupo otro método de pago y ya fue liquidada?</span>
                        </label>
                        <div *ngIf="showCampoMetodoPago">
                            <label [for]="CAMPO_METODO_PAGO">*Metodo de pago: </label>
                            <input [id]="CAMPO_METODO_PAGO" class="form-control mb-3" type="text" [aria-label]="CAMPO_METODO_PAGO"
                                [formControlName]="CAMPO_METODO_PAGO" [maxlength]="CAMPO_METODO_PAGO_MAX_LENGTH">
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-6" style="align-items: center;">
            <button type="button" class="btn btn-nom-success" (click)="updateReprocesoAvanzado()">Guardar</button>
        </div>
        <div class="col-6" style="align-items: center;">
            <button type="button" class="btn btn-nom-danger" (click)="cancelar()">Cancelar</button>
        </div>
    </div>
</div>