import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Archivo } from 'src/app/models/archivo';
import { CommonMessagesService } from 'src/app/services/common-messages.service';
import { DispersionesService } from 'src/app/services/dispersiones.service';
import { FechaService } from 'src/app/util/fecha-service';

@Component({
  selector: 'app-operaciones',
  templateUrl: './operaciones.component.html'
})
export class OperacionesComponent implements OnInit {

  layoutsOperaciones: Archivo[] = new Array();

  fechaInicio: string;
  fechaFin: string;
  formOperaciones: FormGroup;

  formOperacionesEnviar: boolean = false;
  habilitarDescarga: boolean = false;
  hoy: Date = this.fechaService.fechaActual;

  constructor(private formBuilder: FormBuilder,
    private dispersionesService: DispersionesService,
    private fechaService: FechaService,
    private commonMsg: CommonMessagesService,
    private spinner: NgxSpinnerService) {
    this.formOperaciones = this.crearFormularioOperaciones();
  }

  ngOnInit(): void {
  }

  crearFormularioOperaciones(): FormGroup {
    return this.formBuilder.group({
      fechaInicio: [null, Validators.required],
      fechaFin: [null, Validators.required]
    });
  }

  getDataForm() {
    this.fechaInicio = this.fechaService.getFecha(this.formOperaciones.get('fechaInicio').value);
    this.fechaFin = this.fechaService.getFecha(this.formOperaciones.get('fechaFin').value);
  }

  generarLayouts() {
    console.log('generando reportes...');
    this.formOperacionesEnviar = true;
    if (this.formOperaciones.valid) {
      console.log('formulario valido...');
      this.spinner.show('operacionesSpinner');
      this.getDataForm();
      this.dispersionesService.getLayoutsOperaciones(this.fechaInicio, this.fechaFin).subscribe(
        response => {
          console.log('peticion realizada correctamente...');
          this.layoutsOperaciones = response.body;
          this.habilitarDescarga = true;
          this.spinner.hide('operacionesSpinner');
        }, error => {
          console.log(error);
          this.spinner.hide('operacionesSpinner');
          this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
        }
      );
    }
  }

  descargarLayout(layout: number) {
    if (this.habilitarDescarga) {
      if (!this.layoutsOperaciones[layout - 1].isVacio) {
        let data = this.base64ToArrayBuffer(this.layoutsOperaciones[layout - 1].archivo);
        let blob = new Blob([data], { type: this.layoutsOperaciones[layout - 1].contentType });
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = this.layoutsOperaciones[layout - 1].nombre;
        downloadLink.click();
      } else {
        this.commonMsg.crearMSgToast('error', 'Error', 'Layout sin información');
      }
    }
  }

  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

}
