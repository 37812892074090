import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Dispersion } from 'src/app/models/dispersion';

@Component({
  selector: 'app-comisiones-pagadas-modal',
  templateUrl: './comisiones-pagadas-modal.component.html'
})
export class ComisionesPagadasModalComponent implements OnInit {

  @Input() dispersiones: Dispersion[] = new Array();

  dispersionesTnn: Dispersion[] = new Array();

  totalRegistros: number = 0;
  totalDispersion: string = '$';

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.cargarDatos();
  }

  private cargarDatos() {
    console.log(this.dispersiones);
    this.dispersionesTnn = this.dispersiones.filter(dispersion => dispersion.val === false);
    this.totalRegistros = this.dispersiones.filter(dispersion => dispersion.val === false).length;
    let totalDipersionCantidad: number = 0;
    this.dispersionesTnn.forEach(
      dispersion => {
        const cantidadText = dispersion.monto.substring(1, dispersion.monto.length);
        const cantidadText1 = cantidadText.replace(',', '');
        let montoDispersado = parseFloat(cantidadText1);
        totalDipersionCantidad = totalDipersionCantidad + montoDispersado;
        console.log(totalDipersionCantidad);
      }
    );
    this.totalDispersion = this.totalDispersion + totalDipersionCantidad.toFixed(3);
  }

  cerrar(isCambiarEstatus: boolean) {
    let idDispersionList: number[] = new Array();
    if (isCambiarEstatus) {
      this.dispersionesTnn.forEach(dispersion => {
        idDispersionList.push(dispersion.idDispersion);
      });
      this.activeModal.close(idDispersionList);
    } else {
      this.activeModal.dismiss();
    }
    this.cargarDatos();
  }

}
