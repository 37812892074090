import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mensaje-modal',
  templateUrl: './mensaje-modal.component.html'
})
export class MensajeModalComponent implements OnInit {

  @Input() tituloModal: string;
  @Input() mensajeModal: string;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
