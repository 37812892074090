<div class="wrapper d-flex">
    <div class="page-wrapper">
        <main>
            <div class="row title-wraper">
                <div class="col-12 col-sm-6 col-md-6 title-page-fd">
                    <h2>Operaciones</h2>
                </div>
            </div>
            <ngx-spinner name="operacionesSpinner" bdColor="rgba(0, 0, 0, 0.4)" size="default" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
                <p style="color: white"> Cargando... </p>
            </ngx-spinner>
            <div class="row">
                
                <div class="col-12 tabla-operaciones">
                    <form class="needs-validation" novalidate [formGroup]="formOperaciones">
                        <div class="row content-wrapper-inner container-lavels-fd">
                            <div class="col-5">
                                <div class="row">
                                    <div class="col-4">
                                        <label>Fecha Inicio: </label>
                                    </div>
                                    <div class="col-8">
                                        <div class="input-group">
                                            <input formControlName="fechaInicio" id="fechaInicio" type="text"
                                                class="form-control" placeholder="DD-MM-AAAA" size="9" name="fechaDesde"
                                                aria-describedby="inputGroupPrepend" readonly ngbDatepicker
                                                #di="ngbDatepicker"
                                                [ngClass]="formOperacionesEnviar ? formOperaciones.get('fechaInicio').valid ? 'is-valid' : 'is-invalid' : ''">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroupPrepend"
                                                    (click)="di.toggle();">
                                                    <a id="edit">
                                                        <i class="fa fa-calendar-o edit-register"></i>
                                                    </a>
                                                </span>
                                            </div>
                                            <div class="invalid-feedback">
                                                Fecha no puede estar vacia
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="row">
                                    <div class="col-4">
                                        <label>Fecha Fin: </label>
                                    </div>
                                    <div class="col-8">
                                        <div class="input-group">
                                            <input formControlName="fechaFin" id="fechaFin" type="text"
                                                class="form-control" placeholder="DD-MM-AAAA" size="9" name="fechaDesde"
                                                aria-describedby="inputGroupPrepend" readonly ngbDatepicker
                                                #df="ngbDatepicker"
                                                [ngClass]="formOperacionesEnviar ? formOperaciones.get('fechaFin').valid ? 'is-valid' : 'is-invalid' : ''"
                                                [minDate]="formOperaciones.get('fechaInicio').value != null && formOperaciones.get('fechaInicio').valid ?
                                                        { day: formOperaciones.get('fechaInicio').value.day, month: formOperaciones.get('fechaInicio').value.month, year: formOperaciones.get('fechaInicio').value.year} 
                                                        : { day: hoy.getDate(), month: hoy.getMonth() + 1, year: hoy.getFullYear()}">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroupPrepend"
                                                    (click)="df.toggle();">
                                                    <a id="edit">
                                                        <i class="fa fa-calendar-o edit-register"></i>
                                                    </a>
                                                </span>
                                            </div>
                                            <div class="invalid-feedback">
                                                <p *ngIf="formOperaciones.controls['fechaFin'].hasError('required')">
                                                    Fecha no puede estar vacia
                                                </p>
                                                <p *ngIf="formOperaciones.controls['fechaFin'].hasError('ngbDate')">
                                                    La fecha es menor a la Fecha Inicio
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="row">
                                    <div class="col-12">
                                        <button type="submit" (click)="generarLayouts()"
                                            class="btn btn-nom-content">Generar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row content-wrapper-inner mt-2">
                        <div class="table-responsive table-striped  table-bordered operaciones">
                            <table>
                                <tr class="head-top">
                                    <th>Nombre del reporte</th>
                                    <th class="text-center">Descargar</th>
                                </tr>
                                <tr class="head-bottom">
                                    <td>Reporte pagos aplicados Enlace – Internos</td>
                                    <td>
                                        <a id="descargarLayout01" [style]="habilitarDescarga ? 'cursor: pointer' : ''"
                                            data-toggle="modal" data-target="#"
                                            [title]="habilitarDescarga ? 'Descargar Layout' : ''"
                                            (click)="descargarLayout(1)"><i
                                                [class]="habilitarDescarga ? 'state-active ti-download mx-auto d-block' : 'ti-download mx-auto d-block'"></i></a>
                                    </td>
                                </tr>
                                <tr class="head-bottom">
                                    <td>Reporte pagos aplicados TNN – Internos (Cane y Enlace cuando rebasa el tope 5 a
                                        1)</td>
                                    <td>
                                        <a id="descargarLayout02" [style]="habilitarDescarga ? 'cursor: pointer' : ''"
                                            data-toggle="modal" data-target="#"
                                            [title]="habilitarDescarga ? 'Descargar Layout' : ''"
                                            (click)="descargarLayout(2)"><i
                                                [class]="habilitarDescarga ? 'state-active ti-download mx-auto d-block' : 'ti-download mx-auto d-block'"></i></a>
                                    </td>
                                </tr>
                                <tr class="head-bottom">
                                    <td>Reporte pagos aplicados flujos - Externos</td>
                                    <td>
                                        <a id="descargarLayout03" [style]="habilitarDescarga ? 'cursor: pointer' : ''"
                                            data-toggle="modal" data-target="#"
                                            [title]="habilitarDescarga ? 'Descargar Layout' : ''"
                                            (click)="descargarLayout(3)"><i
                                                [class]="habilitarDescarga ? 'state-active ti-download mx-auto d-block' : 'ti-download mx-auto d-block'"></i></a>
                                    </td>
                                </tr>
                                <tr class="head-bottom">
                                    <td>Reporte pagos rechazados</td>
                                    <td>
                                        <a id="descargarLayout04" [style]="habilitarDescarga ? 'cursor: pointer' : ''"
                                            data-toggle="modal" data-target="#"
                                            [title]="habilitarDescarga ? 'Descargar Layout' : ''"
                                            (click)="descargarLayout(4)"><i
                                                [class]="habilitarDescarga ? 'state-active ti-download mx-auto d-block' : 'ti-download mx-auto d-block'"></i></a>
                                    </td>
                                </tr>
                                <tr class="head-bottom">
                                    <td>Reporte operaciones diarias por fecha de creación</td>
                                    <td>
                                        <a id="descargarLayout05" [style]="habilitarDescarga ? 'cursor: pointer' : ''"
                                            data-toggle="modal" data-target="#"
                                            [title]="habilitarDescarga ? 'Descargar Layout' : ''"
                                            (click)="descargarLayout(5)"><i
                                                [class]="habilitarDescarga ? 'state-active ti-download mx-auto d-block' : 'ti-download mx-auto d-block'"></i></a>
                                    </td>
                                </tr>
                                <tr class="head-bottom">
                                    <td>Reporte operaciones diarias por fecha de venta</td>
                                    <td>
                                        <a id="descargarLayout05" [style]="habilitarDescarga ? 'cursor: pointer' : ''"
                                            data-toggle="modal" data-target="#"
                                            [title]="habilitarDescarga ? 'Descargar Layout' : ''"
                                            (click)="descargarLayout(9)"><i
                                                [class]="habilitarDescarga ? 'state-active ti-download mx-auto d-block' : 'ti-download mx-auto d-block'"></i></a>
                                    </td>
                                </tr>
                                <tr class="head-bottom">
                                    <td>Reporte pagos TNN</td>
                                    <td>
                                        <a id="descargarLayout07" [style]="habilitarDescarga ? 'cursor: pointer' : ''"
                                            data-toggle="modal" data-target="#"
                                            [title]="habilitarDescarga ? 'Descargar Layout' : ''"
                                            (click)="descargarLayout(7)"><i
                                                [class]="habilitarDescarga ? 'state-active ti-download mx-auto d-block' : 'ti-download mx-auto d-block'"></i></a>
                                    </td>
                                </tr>
                                <tr class="head-bottom">
                                    <td>Reporte acumulado mensual</td>
                                    <td>
                                        <a id="descargarLayout08" [style]="habilitarDescarga ? 'cursor: pointer' : ''"
                                            data-toggle="modal" data-target="#"
                                            [title]="habilitarDescarga ? 'Descargar Layout' : ''"
                                            (click)="descargarLayout(8)"><i
                                                [class]="habilitarDescarga ? 'state-active ti-download mx-auto d-block' : 'ti-download mx-auto d-block'"></i></a>
                                    </td>
                                </tr>
                                <tr class="head-bottom">
                                    <td>Reporte pagos ALQUIMIA</td>
                                    <td>
                                        <a id="descargarLayout07" [style]="habilitarDescarga ? 'cursor: pointer' : ''"
                                            data-toggle="modal" data-target="#"
                                            [title]="habilitarDescarga ? 'Descargar Layout' : ''"
                                            (click)="descargarLayout(10)"><i
                                                [class]="habilitarDescarga ? 'state-active ti-download mx-auto d-block' : 'ti-download mx-auto d-block'"></i></a>
                                    </td>
                                </tr>
                                <tr class="head-bottom">
                                    <td>Confronta Dispersión - STP</td>
                                    <td>
                                        <a id="descargarLayout07" [style]="habilitarDescarga ? 'cursor: pointer' : ''"
                                            data-toggle="modal" data-target="#"
                                            [title]="habilitarDescarga ? 'Descargar Layout' : ''"
                                            (click)="descargarLayout(11)"><i
                                                [class]="habilitarDescarga ? 'state-active ti-download mx-auto d-block' : 'ti-download mx-auto d-block'"></i></a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>