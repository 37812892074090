import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { CatCentroCosto } from 'src/app/models/cat-centro-costo';
import { CatCentroTrabajo } from 'src/app/models/cat-centro-trabajo';
import { CatDepartamento } from 'src/app/models/cat-departamento';
import { CommonMessagesService } from 'src/app/services/common-messages.service';
import { DispersionesService } from 'src/app/services/dispersiones.service';
import { DepartamentosFormModalComponent } from './departamentos-form-modal.component';
import { MensajeModalComponent } from '../utils/mensaje-modal/mensaje-modal.component';
import { PreguntaModalComponent } from '../utils/pregunta-modal/pregunta-modal.component';
import { CentrosCostosFormModalComponent } from './centros-costos-form-modal.component';
import { CentrosTrabajoFormModalComponent } from './centros-trabajo-form-modal.component';

@Component({
  selector: 'app-administracion-catalogos',
  templateUrl: './administracion-catalogos.component.html'
})
export class AdministracionCatalogosComponent implements OnInit {

  @ViewChild(Table, { static: false }) tableDepartamentos: Table;
  @ViewChild(Table, { static: false }) tableCentrosCostos: Table;
  @ViewChild(Table, { static: false }) tableCentrosTrabajo: Table;

  public departamentos: CatDepartamento[] = new Array();
  public centrosCostos: CatCentroCosto[] = new Array();
  public centrosTrabajo: CatCentroTrabajo[] = new Array();

  public modalOptions: NgbModalOptions;
  public mensajeTablaDepartamentos: string = 'Sin información.';
  public mensajeTablaCentrosCostos: string = 'Sin información.';
  public mensajeTablaCentrosTrabajo: string = 'Sin información.';
  public mostrarPaginadorTablaDepartamentos: boolean = false;
  public mostrarPaginadorTablaCentrosCostos: boolean = false;
  public mostrarPaginadorTablaCentrosTrabajo: boolean = false;

  constructor(private spinner: NgxSpinnerService,
    private commonMsg: CommonMessagesService,
    private modalService: NgbModal,
    private dispersionesService: DispersionesService,
    private config: PrimeNGConfig) {
    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      keyboard: false,
      size: 'sm'
    }
  }

  ngOnInit(): void {
    this.config.setTranslation({
      startsWith: 'Empieza con',
      contains: 'Contiene',
      notContains: 'No contiene',
      endsWith: 'Finaliza con',
      equals: 'Igual',
      notEquals: 'No es igual',
      noFilter: 'Sin Filtro'
    });
    this.obtenerDatosIniciales();
  }

  obtenerDatosIniciales() {
    this.spinner.show();
    this.dispersionesService.getAllDepartamento().subscribe(
      response => {
        this.departamentos = response.body;
        this.mostrarPaginadorTablaDepartamentos = this.departamentos.length > 10;
      }, error => {
        console.log(error);
        this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
        this.mensajeTablaDepartamentos = 'Servicio no disponible.';
      }
    );
    this.dispersionesService.getAllCentroCostos().subscribe(
      response => {
        this.centrosCostos = response.body;
      }, error => {
        console.log(error);
        this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
        this.mensajeTablaCentrosCostos = 'Servicio no disponible.';
      }
    );
    this.dispersionesService.getAllCentroTrabajo().subscribe(
      response => {
        this.centrosTrabajo = response.body;
      }, error => {
        console.log(error);
        this.commonMsg.crearMSgToast('error', 'Intente de nuevo más tarde', 'Servicio no disponible.');
        this.mensajeTablaCentrosTrabajo = 'Servicio no disponible.';
      }
    );
  }

  abrirFormModal(numCatalogo: number) {
    if (numCatalogo == 1) {
      const modalDepartamentoForm = this.modalService.open(DepartamentosFormModalComponent, this.modalOptions);
      modalDepartamentoForm.result.then((respuesta: boolean) => {
        this.obtenerDatosIniciales();
      });
    }
    if (numCatalogo == 2) {
      const modalCentroCostoForm = this.modalService.open(CentrosCostosFormModalComponent, this.modalOptions);
      modalCentroCostoForm.result.then((respuesta: boolean) => {
        this.obtenerDatosIniciales();
      });
    }
    if (numCatalogo == 3) {
      const modalCentroTrabajoForm = this.modalService.open(CentrosTrabajoFormModalComponent, this.modalOptions);
      modalCentroTrabajoForm.result.then((respuesta: boolean) => {
        this.obtenerDatosIniciales();
      });
    }
  }

  public eliminarDepartamento(idDepartamento: number) {
    const modalPregunta = this.modalService.open(PreguntaModalComponent, this.modalOptions);
    modalPregunta.componentInstance.tituloModal = 'Eliminar departamento';
    modalPregunta.componentInstance.textoModal = '';
    modalPregunta.componentInstance.preguntaModal = '¿Está seguro de eliminar este elemento?';
    modalPregunta.result.then((respuesta: boolean) => {
      this.spinner.show();
      if (respuesta) {
        this.dispersionesService.deleteDepartamentoById(idDepartamento).subscribe(
          response => {
            this.spinner.hide();
            const resp = response.body;
            if (resp) {
              this.mostrarMensajeModal('Eliminado', 'El registro se elimino correctamente.');
              this.obtenerDatosIniciales();
            }
          }, error => {
            this.spinner.hide();
            console.log(error);
            this.mostrarMensajeModal('Error', error.error.errorMessage);
          }
        );
      }
    });
  }

  public eliminarCentroCostos(idCentroCosto: number) {
    const modalPregunta = this.modalService.open(PreguntaModalComponent, this.modalOptions);
    modalPregunta.componentInstance.tituloModal = 'Eliminar centro de costos';
    modalPregunta.componentInstance.textoModal = '';
    modalPregunta.componentInstance.preguntaModal = '¿Está seguro de eliminar este elemento?';
    modalPregunta.result.then((respuesta: boolean) => {
      this.spinner.show();
      if (respuesta) {
        this.dispersionesService.deleteCentroCostosById(idCentroCosto).subscribe(
          response => {
            this.spinner.hide();
            const resp = response.body;
            if (resp) {
              this.mostrarMensajeModal('Eliminado', 'El registro se elimino correctamente.');
              this.obtenerDatosIniciales();
            }
          }, error => {
            this.spinner.hide();
            console.log(error);
            this.mostrarMensajeModal('Error', error.error.errorMessage);
          }
        );
      }
    });
  }

  public eliminarCentroTrabajo(idCentroTrabajo: number) {
    const modalPregunta = this.modalService.open(PreguntaModalComponent, this.modalOptions);
    modalPregunta.componentInstance.tituloModal = 'Eliminar centro de trabajo';
    modalPregunta.componentInstance.textoModal = '';
    modalPregunta.componentInstance.preguntaModal = '¿Está seguro de eliminar este elemento?';
    modalPregunta.result.then((respuesta: boolean) => {
      this.spinner.show();
      if (respuesta) {
        this.dispersionesService.deleteCentroTrabajoById(idCentroTrabajo).subscribe(
          response => {
            this.spinner.hide();
            const resp = response.body;
            if (resp) {
              this.mostrarMensajeModal('Eliminado', 'El registro se elimino correctamente.');
              this.obtenerDatosIniciales();
            }
          }, error => {
            this.spinner.hide();
            console.log(error);
            this.mostrarMensajeModal('Error', error.error.errorMessage);
          }
        );
      }
    });
  }

  public mostrarMensajeModal(tituloModal: string, mensajeModal: string) {
    const modalMensaje = this.modalService.open(MensajeModalComponent, this.modalOptions);
    modalMensaje.componentInstance.tituloModal = tituloModal;
    modalMensaje.componentInstance.mensajeModal = mensajeModal;
    modalMensaje.result.then(() => {
    });
  }

}
